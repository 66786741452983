$(function () {

    var $table = $('#costs-profits');

    if ($table.length > 0) {

        var dataTable = $table.DataTable({
            ajax: costsProfitsTableUrl,
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
            scrollX: '50vh',
            serverSide: true,
            order: [[1, 'asc']],
            columns: [
                {
                    orderable: false,
                    render: function (data, type, row) {
                        var elType = row.element.elementtype['element_type_' + appLocale] || '';
                        return '<a href="' + mapUrl.replace('__lsn__', row.element.lsn).replace('__type__', elType.toLowerCase().replace(/\+|\s+|\\|\/|\-/g, '')) + '" target="_blank">' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>'
                    }
                },
                {
                    data: 'element.lsn',
                    name: 'element.lsn',
                    render: function (data, type, row) {
                        return '<a href="' + elementUrl.replace('__id__', row.element.id) + '">' + data + '</a>'
                    }
                },
                {data: 'element.owner.owner', orderable: false, defaultContent: ''},
                {data: 'element.elementtype.element_type_' + appLocale, orderable: false, defaultContent: ''},
                {
                    data: 'element.objective.objective_' + appLocale,
                    name: 'element.objective.objective_' + appLocale,
                    orderable: false,
                    defaultContent: ''
                },
                {data: 'maintenancetype.maintenance_type_' + appLocale, orderable: false, defaultContent: ''},
                {data: 'year', name: 'year', defaultContent: ''},
                {data: 'element.shape.shape_' + appLocale, defaultContent: ''},
                {data: 'calculation_value', name: 'calculation_value', defaultContent: ''},
                {data: 'unit.unit', orderable: false, defaultContent: ''},
                {data: 'gain', orderable: true, defaultContent: ''}

            ],
            fnInfoCallback: function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                    console.log(window.tableHeight);
                }
            }
        });

        var $tbody = $table.find('tbody');

        var maintenance = new Vue({
            el: '#app-costs-profits',
            created: function () {
                this.getFilters();
            },
            data: {
                filters: [],
                total: 0,
                show_archived: false,
                maintenance: [],
                columns: {
                    executed: {index: 2, visible: true},
                    note: {index: 3, visible: true},
                    owner: {index: 4, visible: true},
                    location: {index: 5, visible: true},
                    element: {index: 6, visible: true},
                    objective: {index: 7, visible: true},
                    shape: {index: 8, visible: true},
                    measure: {index: 9, visible: true},
                    year: {index: 10, visible: true},
                    period: {index: 11, visible: true},
                    cycle: {index: 12, visible: true},
                    phasing: {index: 13, visible: true},
                    diameter: {index: 14, visible: true},
                    unit: {index: 15, visible: true},
                    calculation: {index: 16, visible: true},
                    tools: {index: 17, visible: true},
                    waste: {index: 18, visible: true},
                    processing: {index: 19, visible: true},
                    flora: {index: 20, visible: true},
                    fauna: {index: 21, visible: true}
                }
            },
            methods: {
                searchTable: function (clear = '') {
                    this.getFilters();
                },
                customizeTable: function (column) {
                    dataTable.column(column.index).visible(column.visible);
                },
                getFilters: function (updateTable) {
                    var url = costsProfitsGetFiltersUrl;

                    this.$http.get(url).then(function (response) {
                        this.filters = response.data.filters;
                        if(this.show_archived != response.data.show_archived){
                            this.show_archived = response.data.show_archived;
                        }

                        if (updateTable) {
                            dataTable.ajax.reload();
                        }
                    }, function () {
                        console.log('Get filters error!');
                    });
                },
                postFilters: function (filters) {
                    this.$http.post(costsProfitsPostFiltersUrl, {filters: filters}).then(function (response) {
                        this.getFilters(true);
                    }, function () {
                        console.log('Post filters error!');
                    });
                },
                resetFilters: function () {
                    this.postFilters([]);
                },
                changeFilters: function () {
                    this.postFilters(this.filters);
                },
                selectAllMaintenance: function () {
                    var ids = this.maintenance = [];
                    $tbody.find('tr').each(function () {
                        ids.push($(this).addClass('info selected').find('.click-open-notes').eq(0).data('id'));
                    });
                },
                clearSelectAllMaintenance: function () {
                    this.maintenance = [];
                    $tbody.find('tr').removeClass('info selected');
                },
                changeMaintenanceStatus: function (status) {
                    this.$http.post(postStatusUpdate, {
                        status: status,
                        items: this.maintenance
                    }).then(function (response) {
                        if ($.parseJSON(response.body).success) {
                            $.each(this.maintenance, function (index, value) {
                                $tbody.find("[data-status-id='" + value + "']").attr('class', 'status ' + status);
                            });
                        } else {
                            console.log('Status update success false!');
                        }
                    }, function () {
                        console.log('Status update error!');
                    });
                },
                collapse: function (index, filter) {
                    filter.collapsed = !filter.collapsed;
                    this.$http.post(costsProfitsCollapseFilterUrl, {filter: {index: index, collapsed: filter.collapsed}}).then(function(response){
                        console.log(index + 'Collapse state ' + filter.collapsed );
                    }, function(){
                        console.log('Post filters error!');
                    });
                }
            }
        });

        // select table row
        $tbody.on('click', 'tr', function () {
            var $row = $(this);
            var maintenanceId = $row.find('.click-open-notes').eq(0).data('id');
            if ($row.hasClass('info selected')) {
                $row.removeClass('info selected');
                maintenance.maintenance.splice(maintenance.maintenance.indexOf(maintenanceId), 1);
            } else {
                maintenance.maintenance.push(maintenanceId);
                $row.addClass('info selected');
            }
        });

        // remove selects on table rows if new start loading (on scrolling)
        dataTable.on('preXhr', maintenance.clearSelectAllMaintenance);

        $(document).on('click', 'button.updating_planning', function (e) {
            e.preventDefault();
            $.post($(this).data('action'), function (data) {
                maintenance.getFilters(true);
            });
        });

        // prevent caching of modal
        $('#planing_update_confirm').on('hidden.bs.modal', function () {
            $(this).removeData('bs.modal');
        });

    }

});
