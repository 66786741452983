$("#listoptionEditModal .e_submit").on("click", function(e) {
	e.preventDefault();
	var form = $(this).closest(".modal").find("form");
	form.find(".error_message").addClass('hidden');

	form.ajaxSubmit({
		cache: false,
		method: 'POST',
		dataType: 'JSON',
		success: function(response) {
			if (response.success == true) {
				window.location = response.redirect_url;
			} else {
				form.find(".error_message").html(response.error).removeClass('hidden');
			}
		}
	});
});

$("#listoptionEditModal .delete_btn").on("click", function(e) {
	e.preventDefault();
	var form = $(this).closest(".modal").find("form");
	form.find(".error_message").addClass('hidden');
	var button = $(this);

	$.ajax({
		method: "DELETE",
		url: button.data('url'),
		data: {id: form.find('input[name=id]').val()},
		dataType: "json"
	}).done(function (response) {
		if (response.success == true) {
			window.location = response.redirect_url;
		} else {
			form.find(".error_message").html(response.error).removeClass('hidden');
		}
	});
});

// Show the group editing modal and fill it's information
$('#listoptionEditModal').on('show.bs.modal', function (event) {
	var button = $(event.relatedTarget);
	var modal = $(this);

	modal.find('.options').empty();

	// Name and type should be set
	if(button.data('name').length <= 0 || button.data('type').length <= 0) return;

	// Fill form fields with proper information
	$.get(button.data('url'), function(response) {
		var name = button.data('name');
		var type = button.data('type');
		var title = button.data('title');

		$.each(response.list, function(index, object) {
			var listoptionTemplate = $('.form-templates .listoption-template').clone();
			listoptionTemplate.find('input[name="objects[]"]').val(object.id);
			listoptionTemplate.find('input[name="objects[]"]').attr('id', 'object-'+object.id);
			listoptionTemplate.find('input[name="objects[]"]').attr('name', type+'[]');

			listoptionTemplate.find('label').html(object[name]);
			listoptionTemplate.find('label').attr('for', 'object-'+object.id);

			var form = $('form.options');
			// form.find('input[name="type"]').val(type);
			listoptionTemplate.appendTo(form);
		});

		$.each(response.selected, function(index, object) {
			$('#object-'+object.id).prop("checked", true);
		});

		$('h4.modal-title').html(title);
		// if(!response.organisation) return;

		// modal.find('input[name=id]').val(response.organisation.id);
		// modal.find('input[name=organisation]').val(response.organisation.organisation);
		// modal.find('input[name=map]').val(response.organisation.map);

		// // Create the district form fields
		// $.each(response.organisation.districts, function(index, district) {
		// 	var districtTemplate = $('.form-templates .district-template').clone();
		// 	districtTemplate.find('input[name="districts[][district]"]').val(district.district);
		// 	districtTemplate.appendTo('.districts .fields');

		// 	// Create the locations for this district
		// 	$.each(district.locations, function(index, location) {
		// 		var locationTemplate = $('.form-templates .location-template').clone();
		// 		locationTemplate.find('input[name="locations[][location]"]').val(location.location);
		// 		locationTemplate.appendTo('.locations .fields');
		// 	});

		// });

		// modal.find(".modal-content").removeClass("create");
		// modal.find(".modal-content").addClass("edit");
	}, 'json');
});