$(function () {

    var $table = $('#elements-table');

    var columns = [
        {
            orderable: false,
            views: ['default', 'sbb'],
            render: function (data, type, row) {

                if(row.elementtype != null) {
                    if(row.organisation_id == 10) {
                        return '<a target="_blank" href="' + vgwUrl + '\\' + row.id + '">' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>';
                    }
                    else {
                        if(row.element_id != null)
                        {
                            return '<a target="_blank" href="' + cookUrl.replace('_id_', row.element_id) + '">' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>';

                        }
                        return '<a target="_blank" href="' + cookUrl.replace('_id_', row.id) + '">' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>';
                    }
                } else {
                    return '';
                }
            }
        },
        {
            data: 'lsn',
            name: 'lsn',
            searchable: false,
            views: ['default', 'sbb'],
            render: function (data, type, row) {
                if(row.element_id != null)
                {
                    return '<a href="' + elementUrl.replace('__id__', row.element_id) + '">' + data + '</a>'

                }
                else {
                    return '<a href="' + elementUrl.replace('__id__', row.id) + '">' + data + '</a>'

                }
            }
        },

        {
            data: 'notes',
            orderable: false,
            views: ['default', 'sbb', 'vala'],
            render: function (data, type, row) {
                var number_of_notes = 0;
                for (let note of data) {
                    if (!note.maintenance_id && !note.subsidy_id) {
                        number_of_notes++;
                    }
                }

                var number = number_of_notes > 0 ? '' : 'hidden';
                var empty = number_of_notes > 0 ? 'hidden' : '';
                return '<span class="label label-primary label-sm click-open-notes notes_number ' + number + ' " data-element-id="' + row.id  + '">' + number_of_notes + '</span>' +
                    '<span class="label label-default label-sm click-open-notes e_add ' + empty + '" data-element-id="' + row.id + '">+</span>';
            }
        },
        {orderable: false,data: 'elementtype.element_type_' + appLocale, name: 'element_type.element_type_' + appLocale, defaultContent: '', views: ['default', 'sbb']},
        {
            data: 'owner.owner',
            orderable: false,
            name: 'owners.owner',
            defaultContent: '',
            render: function(data, type, row) {
                let owners = '';
                for (let i = 0; i < row.owners.length; i++) {
                    if (i) {
                        owners += ', ' + row.owners[i].owner;
                    } else {
                       owners += row.owners[i].owner;
                    }
                }
                return owners;
            },
            views: ['sbb']
        },
        {
            data: 'subsidy.deeln_naam',
            name: 'owners.owner',
            defaultContent: '',
            render: function(data, type, row) {
                let owners = '';
                for (let i = 0; i < row.owners.length; i++) {
                    if (i) {
                        owners += ', ' + row.owners[i].owner;
                    } else {
                       owners += row.owners[i].owner;
                    }
                }
                return owners;
            },
            views: ['default', 'vala']
        },
        {
            data: 'subsidy.pakketnaam',
            name: 'subsidys.pakketnaam',
            defaultContent: '',
            views: ['default'],
        },
        {
            data: 'subsidy.pakketgroep',
            name: 'subsidys.pakketgroep',
            defaultContent: '',
            views: ['default'],
        },
        {orderable: false,data: 'location.location', name: 'locations.location', defaultContent: '', views: ['default', 'sbb']},
        {orderable: false,data: 'objective.objective_' + appLocale, name: 'objectives.objective_' + appLocale, defaultContent: '', views: ['sbb']},
        {orderable: false,data: 'diameter.diameter', name: 'diameters.diameter', defaultContent: '', views: ['sbb']},
        {
            data: 'length', name: 'length', views: ['sbb'], render: function (data, type, row) {
                return data + ' m';
            }
        },
        {
            data: 'area', name: 'area', views: ['default', 'sbb'], render: function (data, type, row) {
                return data + ' m<sup>2</sup>';
            }
        },
        {data: 'number_in_row', name: 'number_in_row', views: ['sbb']}
    ];

    if(canDeleteElements) {
        columns.unshift(
            {
                orderable: false, views: ['default', 'sbb'], render: function (data, type, row) {
                    return '<a href="#" class="delete_element" data-id="' + row.id + '">' +
                        '<span class="glyphicon glyphicon-trash"></span>' +
                        '</a>';
                }
            }
        );
    }

    if ($table.length > 0) {
        var dataTable = $table.DataTable({
            ajax: {
                url: elementsGetTableUrl,
                type: "get",
                dataType: "json",
                contentType: "application/json; charset=utf-8"
                // success: function(data) {
                //     console.log(data);
                // }
            },
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
            scrollX: '50vh',
            serverSide: true,
            order: canDeleteElements ? [[2, 'asc']] : [[1, 'asc']],
            columns: columns.filter(c => c.views ? c.views.includes(view) : false),
            fnInfoCallback: (oSettings, iStart, iEnd, iMax, iTotal, sPre) => {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                }
            }
        });

        dataTable.on('draw.dt', function() {
            // Remove previous events and then set them again
            $('a.delete_element').off("click");

            // Delete event
            $('a.delete_element').on('click', function(e) {
                console.log(e)
                let deleteElement = confirm("Weet u zeker dat u dit element wilt verwijderen?")
                if(deleteElement === true) {
                    $.ajax({
                        url: elementDeleteUrl + '/' + $(this).attr('data-id'),
                        type: 'DELETE',
                        success: response => { 
                            // Reload the table to show the changes
                            dataTable.ajax.reload();
                        }
                    });
                }
            });
        });

        new Vue({
            el: '#app-elements',
            components: {
          	    Multiselect: window.VueMultiselect.default
        	},
            created: function () {
                this.getFilters(true);
            },
            data: () => ({
                filters: [],
                total: 0,
                timer: 0,
                search: null
            }),
            methods: {
                searchTable: function (clear = '') {
                    if (clear == 'clear') {
                        this.search = "";
                    }

                    // Cancel a previous search, if searching is set
                    if(this.searching) {
                        this.searching.cancel();
                    }

                    // Now create a new one
                    this.searching = _.debounce((() => {
                        if(canDeleteElements) {
                            dataTable.columns(2).search(this.search).draw();
                        } else {
                            dataTable.columns(1).search(this.search).draw();
                        }
                        this.getFilters(true);
                    }), 500)

                    this.searching();
                },
                // Multiselect onSelect
                onSelect: function (option, id) {
                    option.checked = true;
                    this.changeFilters(option, id);
                },
                selectAll: function(id){
					this.filters[id].isCheckAll = !this.filters[id].isCheckAll;
					if(this.filters[id].isCheckAll){
						for (var key in this.filters[id].options) {
							this.filters[id].options[key].checked = true
						}
					}else{
						for (var key in this.filters[id].options) {
							this.filters[id].options[key].checked = false
						}
					}
					this.postFilters(this.filters, false);
				},
                // Datepicker onSelect
                datepickerOnSelect: function(date, e) {
                    console.log(date, e);
                },
                getFilters: function (updateFilters) {
                    var url = elementsGetFiltersUrl;
                    if (this.search) {
                        if(canDeleteElements) {
                            url = url + '?columns[2][search][value]=' + this.search;
                        } else {
                            url = url + '?columns[1][search][value]=' + this.search;
                        }
                    }
                    this.$http.get(url).then(response => {
                        dataTable.ajax.reload();

                        // And update the total
                        this.total = dataTable.data().count();


                        if (updateFilters) {
                            this.filters = response.data;
                            // Refresh the array for vues
                            this.filters = this.filters.slice();
                        }
                    }, function () {
                        console.log('Get filters error!');
                    });

                },
                postFilters: function (filters, refreshFilter) {
                    // clears the timer on a call so there is always x seconds in between calls
                    clearTimeout(this.timer);

                    // if the timer resets before it hits 150ms it will not run
                    this.timer = setTimeout(() => {
                        this.$http.post(elementsPostFiltersUrl, {filters: filters}).then(response => {
                            this.getFilters(refreshFilter);
                        }, function () {
                            console.log('Post filters error!');
                        });
                    }, 600);
                },
                resetFilters: function () {
                    this.search = null;
                    dataTable.columns(1).search('');
                    this.postFilters([], true);
                },
                changeFilters: function (option, id) {
                    if(!option.checked){
						this.filters[id].isCheckAll = false
					}
                    this.postFilters(this.filters, true);
                },
                collapse: function (index, filter) {
                    filter.collapsed = !filter.collapsed;
                    this.$http.post(elementsCollapseFilterUrl, {filter: {index: index, collapsed: filter.collapsed}}).then(function(response){
                        console.log(index + 'Collapse state ' + filter.collapsed );
                    }, function(){
                        console.log('Post filters error!');
                    });
                }
            }
        });
    }
});
