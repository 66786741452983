/* Elements JS */
$(function () {
	$('#import_details_confirm').on('hidden.bs.modal', function () {
		$(this).removeData('bs.modal');
	});
	// ready
	page_var.s_t = 0;
	initSearch();
	FormHandling.collapseFilters();
	FormHandling.submitFilterFormHandler();
	invokePagination();

	// New datepicker init
	$('#element_datepicker').datetimepicker({
		format: 'dd-m-yyyy',
		autoclose: true,
		language: "nl",
		startView: 2,
		minView: 2,
		maxView: 2,
		todayBtn: true,
		pickerPosition: 'bottom-left'
	});

	// Open note modal
	$("body").on("click", '#elements-table .click-open-notes', function (e) {
		var button = $(this);
		var modal = $(".elementsNotes");

		modal.find("input[name='element_id']").val(button.data("element-id"));
		modal.find("input[name='maintenance_id']").val(button.data("id"));

		if (!button.hasClass('e_add')) {
			$.get(getElementNotes + '/' + button.data('element-id'), function (response) {

				page_var.notes_count = response.notes_count;
				modal.find(".notes").replaceWith(response.html);
				modal.modal("show");
			});
		} else {
			page_var.notes_count = 0;
			modal.find(".notes").html("");
			modal.modal("show");
		}

		modal.on('hide.bs.modal', function (e) {
			if (page_var.notes_count == 0) return;
			var parent = button.parents('td');
			parent.find('.e_add').addClass('hidden');
			parent.find('.notes_number').text(page_var.notes_count).removeClass('hidden');
			modal.off('hide.bs.modal');
		});
	});

	$(document).ready(function () {
		// Check or Uncheck All checkboxes
		$("#checkall").change(function () {
			var checked = $(this).is(':checked');
			if (checked) {
				$(".check").each(function () {
					$(this).prop("checked", true);
				});
			} else {
				$(".check").each(function () {
					$(this).prop("checked", false);
				});
			}
			if ($(".check:checked").length == 0) {
				$("#exportBtn").prop('disabled', true);
			}
			else {
				$("#exportBtn").removeAttr("disabled");
			}
		});


		// Changing state of CheckAll checkbox
		$(".check").change(function () {
			if ($(".check:checked").length == 0) {
				$("#exportBtn").prop('disabled', true);
			}
			else {
				$("#exportBtn").removeAttr("disabled");
			}
			if ($(".check").length == $(".check:checked").length) {
				$("#checkall").prop("checked", true);
			} else {
				$("#checkall").removeAttr("checked");
			}

		});
	});

	// input filter
	// numbers only:
	$("body").on("keydown paste", ".form-control[numeric]", function (e) {
		// Allow: backspace, delete, tab, escape, enter and .
		if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
			// Allow: Ctrl+A
			(e.keyCode == 65 && e.ctrlKey === true) ||
			// Allow: home, end, left, right
			(e.keyCode >= 35 && e.keyCode <= 39)) {
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
			e.preventDefault();
		}
	});

	$(document).ready(function () {
		$('.mfp-zoom').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
		});
	});

	$(document).on('click', '.image_caption_block .save-picture-data', function (e) {
		e.preventDefault();
		var parent = $(this).parents('.image-date-edit');
		var input = parent.find("input[type='date']");
		var submitData = input.data();
		submitData.newDate = input.val();
		submitData.name = parent.find("input[type='text']").val();

		$.ajax({
			url: '/elementen/update_picture',
			cache: false,
			method: 'POST',
			dataType: 'JSON',
			data: submitData,
			success: function (response) {
				if (!response.success) {
					$.notify(e.status, "warn");
					return false;
				}
				$.notify("Foto opgeslagen", "success");

				var item = parent.parents(".image_caption_block");
				item.find(".image_date").html(submitData.newDate);
				item.find(".image_name").prop('title', submitData.name).html(submitData.name.substr(0, 50)).tooltip('destroy').tooltip();
				item.removeClass('image_edit');
			}
		});
	});

	$(document).on('click', '.image_caption_block .delete_image', function (e) {
		e.preventDefault();
		var parent = $(this).parents('.image-date-edit');
		var input = parent.find("input[type='date']");
		var submitData = input.data();

		$.ajax({
			url: '/elementen/delete_picture',
			cache: false,
			method: 'POST',
			dataType: 'JSON',
			data: submitData,
			success: function (response) {
				if (!response.success) {
					$.notify(e.status, "warn");
					return false;
				}
				$.notify("Foto verwijderd", "error");

				// remove image and carousel indicator
				var general_carousel = $("#element-carousel");
				var item_to_remove = parent.parents(".item");
				var index_of_item = general_carousel.find('.item').index(item_to_remove);
				item_to_remove.remove();
				general_carousel.find("li[data-slide-to='" + index_of_item + "']").remove();
				general_carousel.carousel('prev');
			}
		});
	});

	$('#filters_modal a.toggle-filters').click(function () {
		$(this).closest('.modal').find('input[type="checkbox"]').removeAttr("checked").trigger("change");
	});

	if ($(".b_filter").length) {
		filterNormalize();
		refinementsSide();
		stickSidebar();
	}

	if ($(".navbar.m_elements").length) {
		// Elements index page
		page_var.elementsIndex = true;
		page_var.fixed_params = {
			w: $(".navbar.m_elements").outerWidth(),
			l: $(".navbar.m_elements").offset().left,
			t: $(".table-elements thead th").first().offset().top
		}
		page_var.fixed_params.css_fixed = {
			position: "fixed",
			width: page_var.fixed_params.w + "px",
			left: page_var.fixed_params.l + "px"
		}
	}

	if ($(".file_pdf_upload").length) {
		var pdf_upload = $(".file_pdf_upload");
		var url = pdf_upload.data('url');
		var is_map_file = !!pdf_upload.data('is_map');

		pdf_upload.find("input").fileupload({
			url: url,
			dataType: 'json',
			autoUpload: true,
			acceptFileTypes: /(\.|\/)(pdf)$/i,
			add: function (e, data) {
				if (is_map_file) {
					var print_area_id = $("select[name=print_area_id]").val();
					if (!print_area_id) return false;
					data.url = url + "/" + print_area_id;
				} else {
					data.url = url;
				}
				data.submit();
			},
			fail: function (e, data) {
				var response = data._response.jqXHR.responseText;
				response = JSON.parse(response.replace(/(^")|("$)/g, ""));

				var error_message = response.error.message;
				$(".file_pdf_upload_result").removeClass('hidden').text(error_message);
			},
			done: function (e, data) {
				$('.progress_pdf').addClass('hidden');
				$('.progress_pdf .progress-bar').css('width', '0%');

				if (!data.result.success) {
					var error_message = data.result.message ? data.result.message : 'Unknown error';
					$(".file_pdf_upload_result").removeClass('hidden').text(error_message);
					return false;
				}
				var files_block = is_map_file ? $('.map_files') : $('.element_files');

				files_block.removeClass('hidden').prepend(
					'<div class="element_file">' +
					(data.result.removeUrl.length ? '<a href="' + data.result.removeUrl + '" class="remove_file"><span class="glyphicon glyphicon-remove"></span></a> ' : "") +
					'<a href="/files/' + data.result.filename + '"' +
					' download target="_blank"><span class="glyphicon glyphicon-download"></span> ' + data.result.filename + '</a>' +
					' (' + data.result.filesize + ')</div>'
				);

				$.notify("Succes");
			},
			progressall: function (e, data) {
				var progress = parseInt(data.loaded / data.total * 100, 10);
				$('.progress_pdf .progress-bar').css('width', progress + '%');
			},
			submit: function (e, data) {
				$(".file_pdf_upload_result").addClass('hidden');
				$('.progress_pdf').removeClass('hidden');
			}
		}).prop('disabled', !$.support.fileInput)
			.parent().addClass($.support.fileInput ? undefined : 'disabled');
	}

	$("body").on("click", ".edit_note", function (e) {
		e.preventDefault();
		$(this).parents(".note").toggleClass("editing");
	});

	$("body").on("click", ".show_edit_note .cancel_btn", function (e) {
		e.preventDefault();
		var note_block = $(this).parents(".note");
		note_block.find('textarea').val(note_block.find(".show_note").html());
		$(this).parents(".note").removeClass("editing");
	});

	$("body").on("click", ".show_edit_note .delete_btn", function (e) {
		e.preventDefault();
		if (!confirm("Weet je het zeker?")) return false;
		var self = $(this);
		var note_block = self.parents(".note");
		var url = self.data('url');
		var data = {
			note_id: note_block.data('id')
		};
		$.post(url, data, function (response) {
			if (!response.success) {
				$.notify(e.status, "warn");
				return false;
			}
			$.notify("Notitie verwijderd");
			note_block.remove();
			if (page_var.notes_count) page_var.notes_count--;
		}, "json");
	});

	// Save subsidy note
	$("body").on("click", "#element_subsidy .e_subsidy_note_form .save-subsidy-note", function (e) {
		e.preventDefault();
		var form = $(this).closest("form");
		var data = form.serialize();

		form.closest(".element-form").addClass("m_loading");

		$.post(form.attr("action"), data, function (response) {
			form.closest(".element-form").removeClass("m_loading m_edit")
			form.find(".form-control.m_changed").removeClass("m_changed")
			form.find(".form-control").val('');

			if (response.success == true) {
				$.notify("Notitie opgeslagen", "success");
				form.closest("#element_subsidy").find(".notes").html(response.content.replace(/\\/g, ''));
				form.closest(".e_subsidy_note_form").collapse('toggle');
				page_var.notes_count++;
			} else {
				$.notify(response.content, "warn");
			}

		}, "json")
			.fail(function (e) {
				form.closest(".element-form").removeClass("m_loading m_edit");
				$.notify(e.status, "warn");
			});
	});

	$("body").on("click", ".show_edit_note .submit_btn", function (e) {
		e.preventDefault();
		var self = $(this);
		var note_block = self.parents(".note");
		var url = self.data('url');
		var data = {
			note_id: note_block.data('id'),
			note_content: note_block.find('textarea').val()
		};
		$.post(url, data, function (response) {
			if (!response.success) {
				$.notify(e.status, "warn");
				return false;
			}
			$.notify("Notitie opgeslagen", "success");
			note_block.find(".show_note").html(data.note_content);
			note_block.removeClass("editing");
		}, "json");
	});

	// Save note
	$("body").on("click", ".element .measure .click-save-mnote, .elementsNotes .click-save-mnote", function (e) {
		e.preventDefault();
		var form = $(this).closest("form");
		var data = form.serialize();

		form.closest(".element-form").addClass("m_loading");

		$.post(form.attr("action"), data, function (response) {
			form.closest(".element-form").removeClass("m_loading m_edit")
			form.find(".form-control.m_changed").removeClass("m_changed")
			form.find(".form-control").val('');

			if (response.success == true) {
				$.notify("Notitie opgeslagen", "success");
				form.closest(".e_maintenance_form").find(".notes").html(response.content.replace(/\\/g, ''));
				form.closest(".element-form").find(".notes").html(response.content.replace(/\\/g, ''));
				form.closest(".e_element_note_form").collapse('toggle');
				page_var.notes_count++;
			} else {
				$.notify(response.content, "warn");
			}

		}, "json")
			.fail(function (e) {
				form.closest(".element-form").removeClass("m_loading m_edit");
				$.notify(e.status, "warn");
			});
	});

	if ($(".element-single").length) {
		page_var.elementSingle = true;
		$("body").addClass("page-element-single");
		// collapsize(".history-collapsible");

		// sticky header
		page_var.fixed_params = {
			w: $(".navbar").outerWidth(),
			l: $(".navbar").offset().left
		}
		page_var.fixed_params.css_fixed = {
			position: "fixed",
			width: page_var.fixed_params.w + "px",
			left: page_var.fixed_params.l + "px"
		}

		$(".navbar, .breadcrumb, .element-name").css(page_var.fixed_params.css_fixed);
		$(".breadcrumb").css("top", 72 + "px");
		$(".element-name").css("top", 72 + "px");

		// assume this will be valid for global-information only
		$("body").on("click", ".element-form .click-edit", function (e) {
			e.preventDefault();
			$('#ownersSelect').prop('disabled', false).trigger("chosen:updated");
			const form = $(this).closest(".element-form");
			form.toggleClass("m_edit");

			switch (form.data("form-name")) {
				case 'global-information':
					if ($.fn.chosen && !form.find(".chosen-select.m_single:first > .chosen-container").length) {
						form.find(".chosen-select.m_single > select").chosen({
							max_selected_options: 1
						})
					}
					break;
				default:
					break;
			}
		})

		$("body").on("keyup change", ".element .form-control", function () {
			$(this).addClass("m_changed");
		});
		$(".element .collapse").on("hidden.bs.collapse", function (e) {
			$(this).closest(".element-form").find(".e_hide_show").removeClass("m_open");
		});
		$(".element .collapse").on("shown.bs.collapse", function (e) {
			var obj = $(this);
			obj.closest(".element-form").find(".e_hide_show").addClass("m_open");
			/*
			 $('html, body').animate({
			 scrollTop: obj.offset().top
			 }, "fast");
			 */
		});

		$("body").on("click", ".element-form .click-add", function (e) {
			e.preventDefault();

			var form = $(this).closest(".species-list-box");

			var tpl = form.find(".e_tpl").html();

			$(this).closest(".species-list__list").find(".e_add_new").prepend(tpl);

			var clean = $(this).closest(".species-list__list").find(".clean");
			clean.find(".chosen-select.m_single > select:visible")
				.addClass("form-control")
				.chosen();

			clean.find(".e_date").datepicker({
				dateFormat: 'yy/mm/dd',
				onClose: function (date, obj) {
					obj.input.closest(".e_species_add__el").find("textarea").focus();
				}
			});
			clean.removeClass("clean");
		});

		$("body").on("click", ".species-control.e_add_new .e_remove", function (e) {
			$(this).closest(".e_species_add__el").remove();
		});

		$("body").on("mouseenter", ".species-control.e_add_new .e_species_add__el .e_remove", function (e) {
			$(this).closest(".e_species_add__el").addClass("m_rhover");
		});
		$("body").on("mouseleave", ".species-control.e_add_new .e_species_add__el .e_remove", function (e) {
			$(this).closest(".e_species_add__el").removeClass("m_rhover");
		});

		$("body").on("change", ".element-form .species__el input:checkbox", function (e) {
			var obj = $(this).closest(".species__el");
			if ($(this).is(":checked")) {
				obj.addClass("active");
			} else {
				obj.removeClass("active");

			}
		});
		//threats
		$("body").on("change", ".element-form .threats-list__list select", function () {
			$(this).closest(".e_threats_add__el").find(".e_date").focus();
		});

		$("body").on("click", ".element-form .click-add-threats", function (e) {
			e.preventDefault();

			var form = $(this).closest(".threats-list-box");

			var tpl = form.find(".e_tpl").html();

			$(this).closest(".threats-list__list").find(".e_add_new").prepend(tpl);

			var clean = $(this).closest(".threats-list__list").find(".clean");
			clean.find(".chosen-select.m_single > select:visible")
				.addClass("form-control")
				.chosen();

			clean.find(".e_date").datepicker({
				dateFormat: 'yy/mm/dd',
				onClose: function (date, obj) {
					obj.input.closest(".e_threats_add__el").find("textarea").focus();
				}
			});
			clean.removeClass("clean");
		});

		$("body").on("click", ".threats-control.e_add_new .e_remove", function (e) {
			$(this).closest(".e_threats_add__el").remove();
		});

		$("body").on("mouseenter", ".threats-control.e_add_new .e_threats_add__el .e_remove", function (e) {
			$(this).closest(".e_threats_add__el").addClass("m_rhover");
		});
		$("body").on("mouseleave", ".threats-control.e_add_new .e_threats_add__el .e_remove", function (e) {
			$(this).closest(".e_threats_add__el").removeClass("m_rhover");
		});

		$("body").on("change", ".element-form .threats__el input:checkbox", function (e) {
			var obj = $(this).closest(".threats__el");
			if ($(this).is(":checked")) {
				obj.addClass("active");
			} else {
				obj.removeClass("active");

			}
		});

		$("body").on("change", ".element-form .threats-list__list select", function () {
			$(this).closest(".e_threats_add__el").find(".e_date").focus();
		});

		$("body").on("click", ".element-form .click-save", function (e) {
			e.preventDefault();

			const form = $(this).closest(".element-form");

			if (form.find(".error").length) return;

			var data = {
				element_id: form.closest(".element").data("id"),
				form_piece: form.data("form-name")
			};

			form.find(".landscape_element").each(function () {
				data['landscape_element'] = 'true';
			});

			form.find(".form-control").each(function () {
				var d = $(this).data();
				if (d.name) {
					if (typeof d.value != "undefined") {
						data[d.name] = d.value;
					} else {
						data[d.name] = $(this).text();
					}
				}
			});

			form.find(".element-datepicker").each(function () {
				var d = $(this)[0];

				if (typeof d.value != "undefined") {
					data[d.name] = d.value;
				}
			});

			form.find(".chosen-select:not(.is_array) > select").each(function () {
				var d = $(this);
				data[d.attr("name")] = d.val();
			});

			form.find(".is_array > select").each(function () {
				var d = $(this);
				var name = d.attr("name");
				var selectedValues = d.val();
				if (selectedValues && selectedValues.length > 0) {
					data[name] = selectedValues; // Assign the array of selected values
				} else {
					delete data[name]; // Remove the key from data if no values are selected
				}
			});

			form.addClass("m_loading");

			$.post(generalFormUrl, data, function (response) {
				console.log(response.content);
				if (response.success == true) {
					$(".element .global-information").replaceWith(response.content.replace(/\\/g, ''));
				} else {
					$.notify(response.content, "warn");
				}
				showHistory(data.element_id);

				form.removeClass("m_loading m_edit");
			}, "json")
				.fail(function (e) {
					form.removeClass("m_loading m_edit");
					$.notify(e.status, "warn");
				});
		});



		$("body").on("click", ".e_importdetail", function (e) {
			e.preventDefault();

			var obj = $(this);
			if (obj.prop("disabled") !== false) return;

			var form = obj.closest(".modal").find("form");
			var data = form.serialize();

			console.log(data);

			$.post(importDetailsUrl, data, function (response) {
				if (response.success == true) {
					form.closest(".modal").modal("hide");
					$.notify(form.find(".e_success").text(), "success");
				} else {
					$.notify(response.content, "error");
				}

				form.removeClass("m_loading m_edit");
			}, "json")
				.fail(function (e) {
					form.removeClass("m_loading m_edit");
					$.notify(e.status, "error");
				});
		});

		$("body").on("click", ".element-form .click-save-species", function (e) {
			e.preventDefault();

			var form = $(this).closest(".element-form");
			if (form.find(".error").length) return;
			var description = "";
			var placeholder = "";

			var data = {
				element_id: form.closest(".element").data("id"),
				form_piece: form.data("form-name"),
				els_fauna: [],
				els_flora: [],
				remove_fauna: [],
				remove_flora: []
			};
			form.find(".species-list__list .e_fauna__el").each(function (i) {
				description = $(this).find("textarea").val();
				placeholder = $(this).find("textarea").prop('placeholder');

				data.els_fauna.push({
					'fauna_id': $(this).find("select").val(),
					'fauna_date': $(this).find(".e_date").val(),
					'fauna_count': $(this).find(".e_count").val(),
					'fauna_desc': description == placeholder ? "" : description
				});
			});
			form.find(".species-list-box-fauna .species__el .e_remove input:checked").each(function () {
				data.remove_fauna.push($(this).attr("name"));
			});

			form.find(".species-list__list .e_flora__el").each(function (i) {
				description = $(this).find("textarea").val();
				placeholder = $(this).find("textarea").prop('placeholder');

				data.els_flora.push({
					'flora_id': $(this).find("select").val(),
					'flora_date': $(this).find(".e_date").val(),
					'flora_count': $(this).find(".e_count").val(),
					'flora_desc': description == placeholder ? "" : description
				});
			});
			form.find(".species-list-box-flora .species__el .e_remove input:checked").each(function () {
				data.remove_flora.push($(this).attr("name"));
			});

			form.addClass("m_loading");

			$.post(speciesFormUrl, data, function (response) {
				if (response.success == true) {
					$(".element .element-form.species").replaceWith(response.content.replace(/\\/g, ''));
				} else {
					$.notify(response.content, "warn");
				}
				form.removeClass("m_loading m_edit");

				showHistory(data.element_id);
			}, "json")
				.fail(function (e) {
					form.removeClass("m_loading m_edit");
					$.notify(e.status, "warn");
				});
		})
		//threats
		$("body").on("click", ".element-form .click-save-threats", function (e) {
			e.preventDefault();

			var form = $(this).closest(".element-form");
			if (form.find(".error").length) return;
			var description = "";
			var placeholder = "";

			var data = {
				element_id: form.closest(".element").data("id"),
				form_piece: form.data("form-name"),
				els_plagues: [],
				els_diseases: [],
				remove_plagues: [],
				remove_diseases: [],
			};
			form.find(".threats-list-box-plagues .threats__el .e_remove input:checked").each(function () {
				data.remove_plagues.push($(this).attr("name"));
			});

			form.find(".threats-list__list .e_plagues__el").each(function (i) {
				description = $(this).find("textarea").val();
				placeholder = $(this).find("textarea").prop('placeholder');

				data.els_plagues.push({
					'plagues_id': $(this).find("select").val(),
					'plagues_date': $(this).find(".e_date").val(),
					'plagues_count': $(this).find(".e_count").val(),
					'plagues_desc': description == placeholder ? "" : description
				});
			});
			form.find(".threats-list-box-plagues .threats__el .e_remove input:checked").each(function () {
				data.remove_plagues.push($(this).attr("name"));
			});

			form.find(".threats-list-box-diseases .threats__el .e_remove input:checked").each(function () {
				data.remove_diseases.push($(this).attr("name"));
			});

			form.find(".threats-list__list .e_diseases__el").each(function (i) {
				description = $(this).find("textarea").val();
				placeholder = $(this).find("textarea").prop('placeholder');

				data.els_diseases.push({
					'diseases_id': $(this).find("select").val(),
					'diseases_date': $(this).find(".e_date").val(),
					'diseases_count': $(this).find(".e_count").val(),
					'diseases_desc': description == placeholder ? "" : description
				});
			});
			form.find(".threats-list-box-diseases .threats__el .e_remove input:checked").each(function () {
				data.remove_diseases.push($(this).attr("name"));
			});

			form.addClass("m_loading");

			$.post(threatsFormUrl, data, function (response) {
				if (response.success == true) {
					$(".element .element-form.threats").replaceWith(response.content.replace(/\\/g, ''));
				} else {
					$.notify(response.content, "warn");
				}
				form.removeClass("m_loading m_edit");

				showHistory(data.element_id);
			}, "json")
				.fail(function (e) {
					form.removeClass("m_loading m_edit");
					$.notify(e.status, "warn");
				});

		})

		// element maintenance
		// Add a handler to show/hide the note adding window
		$("body").on("click", ".maintenance .e_show_maintenance", function (e) {
			e.preventDefault();
			var obj = $(this).closest("form");
			obj.toggleClass("m_open")
				.find("textarea").toggle();
		});

		// handler for status updater for touch devices that don't have a hover
		$('.status-box .status').bind("touchstart", function (e) {
			e.preventDefault();
			$(this).toggleClass('status-hover');
			// $('.status-box .status').toggleClass('status-hover');
		})

		// update status
		$(".element .measure .status-item").on("click touchend", updateStatus);

		function updateStatus(e) {
			e.preventDefault();
			var obj = $(this).closest(".status-box");
			var obj_status = obj.children(".status").first();

			var item = $(this);
			var item_status = item.data("status");
			var maintenanceId = item.data("maintenance");
			var new_top = item.position().top;

			if (obj_status.attr("data-status") != item_status) {
				// post
				var form = $(this).closest(".element-form");
				if (form.find(".error").length) return;

				var data = {
					maintenance_id: maintenanceId,
					element_id: form.closest(".element").attr("data-id"),
					status: item_status
				};

				form.addClass("m_loading");

				$.post(maintenanceFormUrl, data, function (response) {
					if (!response) {
						$.notify(e.status, "warn");
						return false;
					}

					obj_status.removeClass(obj_status.attr("data-status")).attr("data-status", item_status).addClass(item_status);
					obj.children(".status-update").css("top", "-" + new_top + "px");
					item.addClass("active").siblings().removeClass("active");
					form.removeClass("m_loading m_edit");

					showHistory(data.element_id);
				}, "json")
					.fail(function (e) {
						form.removeClass("m_loading m_edit");
						$.notify(e.status, "warn");
					});
			}
		}

		// add maintenance form
		$("body").on("click touchstart", ".element .maintenance .e_add", function (e) {
			e.preventDefault();
			if ($(this).hasClass("disabled")) return;

			var obj = $(".element .maintenance");
			var form = $('#template-maintenance').clone().removeAttr('id').removeClass('hidden');
			obj.find(".e_form_add.tpl").after(form);

			toggleMaintenanceEditing($(".e_maintenance_form[data-new]"));
			// validize.validate_ini('[data-validate]');
			$(this).addClass("disabled");

		});

		// delete maintenance item
		$("body").on("click", ".element .maintenance .e_delete_maintenance", function (e) {
			var modal = $($(this).data("target"));
			modal.find("input[name='maint_id']").val($(this).data("id"));
			modal.find(".modal-body span").text($(this).closest(".e_maintenance_form").find(".e_type").text());
		});
		// confirm to delete maintenance item
		$("body").on("click", "#confirmDeleteMaint .e_delete_confirm", function (e) {
			e.preventDefault();
			var obj = $(this);
			if (obj.prop("disabled") !== false) return;

			var form = obj.closest(".modal").find("form");
			var data = form.serialize();

			obj.prop("disabled", "disabled");

			$.post(maintenanceRemoveUrl, data, function (response) {
				if (response.success !== undefined) {
					form.closest(".modal").modal("hide");
					$.notify(form.find(".e_success").text());
					$(".element .maintenance.element-form").replaceWith(response.content.replace(/\\/g, ''));
				} else {
					$.notify(response.fail, "danger");
				}
				obj.removeAttr("disabled");
				showHistory(data.id);
			}, "json")
				.fail(function (e) {
					obj.removeAttr("disabled");
					$.notify("Onderhoudsmaatregel verwijderd", "error");
				});
		});

		// remove maintenance form
		$("body").on("click", ".element .maintenance .e_maintenance_form .e_remove", function (e) {
			e.preventDefault();
			var obj = $(this).closest(".well");
			obj.closest(".maintenance").find(".e_add").removeClass("disabled");
			obj.remove();
		});

		// edit maintenance item
		$("body").on("click", ".element .maintenance .click-edit-maint", function (e) {
			e.preventDefault();

			var form = $(this).closest(".e_maintenance_form");
			form.toggleClass("m_edit");
			toggleMaintenanceEditing(form);
		});

		// save maintenance item
		$("body").on("click", ".element .maintenance .e_maintenance_form .e_save", function (e) {
			e.preventDefault();
			$(this).closest("form").submit();
		});
		$("body").on("submit", ".element .maintenance .e_maintenance_form form", function (e) {
			e.preventDefault();
			var form = $(this);
			if (page_var.maintenanceEditSent == true) return;

			var data = form.serializeObject();
			data['element_id'] = form.closest(".element").data("id");

			if (data['period'] !== undefined) {
				data['year'] = data['period'].split(" ")[1];
				data['period'] = data['period'].split(" ")[0];
			}
			// delete data['unit']; // symbolic for front-end, unit id is connected with maintenance type id
			page_var.maintenanceEditSent = true;

			$.ajax(
				{
					url: form.attr("action"),
					method: 'POST',
					data: data,
					dataType: 'json',
					formRef: form
				}
			).done(function (response) {
				var parent = form.parent();
				if (response.success) {
					form.closest(".element-form").replaceWith(response.content.replace(/\\/g, ''));

					// Rebind status selection
					$(".element .measure .status-item").on("click touchend", updateStatus)

					showHistory(data.element_id);
				} else {
					$.notify(response.fail);
				}
				page_var.maintenanceEditSent = false;
				// validize.validate_ini($('.element .maintenance [data-validate]'));
			}).error(function (e) {
				let response = e.responseJSON;
				let form = this.formRef;
				console.log(response);
				console.log(e);

				// Clear existing error messages
				$('.formErrors').remove();

				// Handle validation errors
				if (response && response.errors) {
					let errors = Object.values(response.errors).flatMap(e => e);
					let errorDiv = $('<div class="row error formErrors"><div class="col-md-12"></div></div>');
					errors.forEach(error => {
						errorDiv.find('.col-md-12').append(`<p>${error}</p>`);
					});

					// Prepend the error messages to the form
					form.prepend(errorDiv);
				}

				// Show appropriate notification based on the status code
				if (e.status === 422) {
					$.notify('Formulier incompleet', 'warn');
				} else {
					$.notify('Formulier opgeslagen', 'success');
				}

				form.toggleClass('m_edit');
				toggleMaintenanceEditing(form);
				page_var.maintenanceEditSent = false;
			});


			return false;
		});

		// $(".element .maintenance .e_add .btn").click();
		$.fn.datetimepicker.dates['nl'];

		$('body').on('click', '.image_caption_block .image_edit_button', function (e) {
			e.preventDefault();
			$(this).parents('.image_caption_block').addClass('image_edit');
			$(this).parents('.image-date-edit').find("input[type='text']").focus();
		});

		// Rotate an image
		$('body').on('click', '.image_caption_block .rotate-image', function (e) {
			e.preventDefault();
			// Get the selected images information
			let imageId = e.currentTarget.dataset["imageId"];
			let imageSrc = e.currentTarget.dataset["imageSrc"];

			$.post(`/elementen/rotate/image`, { imageId: imageId }, function () {
				$(`img[src*='${imageSrc}']`).attr("src", imageSrc + "?" + new Date().getTime());
			})
		});

		$('body').on('click', '.image_caption_block .cancel', function (e) {
			e.preventDefault();
			$(this).parents('.image_caption_block').removeClass('image_edit');
		});


		$('body.page-element-single').on('click', '.e_cancel, .e_approve, .e_delete', function (e) {
			e.preventDefault();
			var self = $(this);
			var root_obj = self.parents(".b_forms_maintenance__el");

			if (self.hasClass("e_delete")) {
				if (!confirm("Weet je het zeker?")) return false;

				root_obj.addClass("m_loading");
				$.ajax({
					url: root_obj.data("action"),
					type: 'DELETE',
					success: function (response) {
						root_obj.removeClass("m_loading");
						if (response.success == true) {
							root_obj.remove();
							showHistory(response.element_id);
						} else {
							$.notify("Element verwijderd", "warn");
						}
					},
					dataType: 'json'
				});
			} else {
				var to_approve = self.hasClass("e_approve");

				root_obj.addClass("m_loading");
				$.post(root_obj.data('action'), { action: to_approve }, function (response) {
					if (response.success == true) {
						showUpdatedSpecies(response.element_id);
						showUpdatedThreats(response.element_id);
						showHistory(response.element_id);

						var new_class = to_approve ? "panel-success" : "panel-danger";

						root_obj.find('.e_delete').toggleClass("hidden", new_class == "panel-success");
						root_obj.removeClass("panel-success").removeClass("panel-default").removeClass("panel-danger")
							.addClass(new_class).removeClass("m_loading");
						root_obj.find(".controls > span").removeClass('active').attr('disabled', false);
						self.addClass('active').attr('disabled', true);
						root_obj.find(".no-action").remove();
					} else {
						root_obj.removeClass("m_loading");
						$.notify(e.status, "warn");
					}
				}, "json");
			}
		});

		if ($(".image_caption_block").length) $(".image_caption_block").find(".image_name").tooltip();

		$('body.page-element-single').on('click', '.select_map .remove', function (e) {
			$(this).parents('.select_map').remove();
			return false;
		});

		$('body.page-element-single').on('click', '.select_owner .remove', function (e) {
			$(this).parents('.select_owner').remove();
			return false;
		});

		$('body.page-element-single').on('click', '.add_map_form', function (e) {
			var form = $('#add_more_maps').clone().removeAttr('id').removeClass('hidden');
			form.find('.print_area_id select').prop('name', 'print_area_ids[]').chosen();
			form.find('.subid select').prop('name', 'subids[]').chosen();
			$(".maps_block").append(form);
			return false;
		});
		$('body.page-element-single').on('click', '.add_owner_form', function (e) {
			var form = $('#add_more_owners').clone().removeAttr('id').removeClass('hidden');
			form.find('.owner_id select').prop('name', 'owner_ids').chosen();
			$(".owners_block").append(form);
			return false;
		});
	}

	if ($(".filter-content").length) {
		initScroller();
	}
});

function initScroller() {
	$('.filter-content').scrollbar('destroy');

	$('.filter-content').scrollbar({
		"autoScrollSize": false,
		"scrollx": $('.external-scroll_x')
	});
}

function stickSidebar() {
	$(window).unbind('.sidebarScroll');
	var sidebar = $(".b_filter");
	if (!sidebar.length) return false;

	var top = parseFloat(sidebar.css('margin-top').replace(/auto/, 0));
	var gap = 30;
	var winHeight = $(window).height();
	var footer = $('footer');
	var contentHeight = $(".filter-content").height();

	$(window).bind("scroll.sidebarScroll", function () {
		var y = $(this).scrollTop();
		var height = sidebar.height();
		var footerTop = footer.offset().top - parseFloat(footer.css('margin-top').replace(/auto/, 0));
		var sidebarIsBiggerThanContent = height + gap > contentHeight - 200;
		var sidebarIsTooSmall = top + height + gap < winHeight;

		var sidebar_height = winHeight - 300;

		if (y == 0 || sidebarIsBiggerThanContent) {
			// if we are on top of the page || sidebar is too big
			sidebar.removeClass('sidebarfixed').css('top', '0px').css('height', 'auto');
		} else if (y + winHeight > footerTop && top + height + gap >= winHeight - footer.height()) {
			// if current scroll position > footer, we pin sidebar to footer
			sidebar.addClass('sidebarfixed').css('top', footerTop - height - y - gap - top + 'px').css('height', sidebar_height + 'px');
		} else if (sidebarIsTooSmall) {
			// if sidebar is too small
			sidebar.addClass('sidebarfixed').css('top', '0px').css('height', sidebar_height + 'px');
		} else {
			// we are in the middle of the page
			sidebar.addClass('sidebarfixed').css('top', '0px').css('height', sidebar_height + 'px');
		}
	});
	$(window).scroll();
}

window.onload = function () {
	// load
	$(window).resize();
}

$(window).resize(function (e) {
	// resize
	page_var.w_w = $(window).width();
	page_var.w_h = $(window).height();

	if ($(".b_filter").length) {
		filterNormalize();
		stickSidebar();
	}

	// sticky header elements table
	if (page_var.elementsIndex !== undefined && page_var.elementsIndex == true) {
		$(".navbar.m_elements, .elements-list-header").removeAttr("style");

		page_var.fixed_params = {
			w: $(".navbar.m_elements").outerWidth(),
			l: $(".navbar.m_elements").offset().left
			// t: $(".table #elements .thead > th").first().offset().top
		}
		page_var.fixed_params.css_fixed = {
			position: "fixed",
			width: page_var.fixed_params.w + "px",
			left: page_var.fixed_params.l + "px"
		}

		$(".navbar.m_elements, .elements-list-header").css(page_var.fixed_params.css_fixed);
		// $(".elements-list-header").css("top", 70 + "px");
	}

	// sticky header elements single
	if (page_var.elementSingle !== undefined && page_var.elementSingle == true) {
		$(".navbar, .breadcrumb, .element-name").removeAttr("style");

		page_var.fixed_params = {
			w: $(".navbar").outerWidth(),
			l: $(".navbar").offset().left
		}
		page_var.fixed_params.css_navbar_fixed = {
			position: "fixed",
			width: page_var.fixed_params.w + "px",
			left: page_var.fixed_params.l + "px"
		}
		page_var.fixed_params.css_fixed = {
			position: "fixed"
		}

		$(".navbar, .breadcrumb").css(page_var.fixed_params.css_navbar_fixed);
		$(".element-name").css(page_var.fixed_params.css_fixed);
		$(".breadcrumb").css("top", 72 + "px");
		$(".element-name").css("top", 72 + "px");
	}
});

$(window).scroll(function (e) {
	// scroll
	page_var.s_t = $(window).scrollTop();
	if (page_var.elementsIndex !== undefined && page_var.elementsIndex == true) {
		$(".table thead th div, div.filters_header").css("top", page_var.s_t + "px");
	}
});

function showHistory(id) {
	if (id !== undefined) {
		$(".element .history.element-form").addClass("m_loading");

		$.get(historyFormUrl, function (response) {
			$(".element .history.element-form").removeClass("m_loading");

			var uncollapsed = !$(".element .history .history-collapsible").hasClass("collapsed");
			$(".element .history").replaceWith(response);
			collapsize(".element .history .history-collapsible", { uncollapsed: uncollapsed });
		})
	}
}

function showUpdatedSpecies(id) {
	if (id === undefined) return false;
	var block = $(".element .element-form.species");
	block.addClass("m_loading");

	$.get(speciesGetUrl, function (response) {
		block.replaceWith(response.content.replace(/\\/g, ''));
		block.removeClass("m_loading");
	});
}

function showUpdatedThreats(id) {
	if (id === undefined) return false;
	var block = $(".element .element-form.threats");
	block.addClass("m_loading");

	$.get(threatsGetUrl, function (response) {
		block.replaceWith(response.content.replace(/\\/g, ''));
		block.removeClass("m_loading");
	});
}

function collapsize(obj, params) {
	if (!obj) return;
	var obj = $(obj);
	if (params === undefined) params = { uncollapsed: false };
	if (obj.find(".history__el").length > 1) {
		var h = obj.find(".history__el").first().outerHeight(true);
		var h_total = obj.height();

		if (params.uncollapsed != true) {
			obj
				.height(h * 3)
				.addClass("collapsed");
		}

		obj.find(".e_collapse").on("click", function (e) {
			e.preventDefault();
			var status = obj.hasClass("collapsed");

			if (status) {
				obj.animate({
					height: h_total
				}, "fast");
			} else {
				obj.animate({
					height: h * 3
				}, "fast");
			}

			obj.toggleClass("collapsed");

		})

	}
}

function initSearch() {
	var b_search = $(".b_search");
	if (!b_search.length) return;
	// var lsn_timeout = false;
	var previous_lsn = $(".b_search_q").val();

	b_search.find(".b_search_q").on("keyup", function (e) {
		// if(lsn_timeout) clearTimeout(lsn_timeout);
		var v = $(this).val();

		if (v.length == 1) {
			$(this).removeClass("m_changed");
			return false;
		}

		$(this).toggleClass("m_changed", v != '');
	});
}

var FormHandling = {
	reloadList: function () {
		var url = $('#filters_modal form').attr('action');
		var token = $('#filters_modal form input[name="_token"]').val();

		$.post(url, { _token: token }, function (response) {
			if (response.success == true) {
				$('#counter').html(response.count);
				$('.filter-content').replaceWith(response.content.replace(/\\/g, ''));
				$("#table_customize_modal :checkbox").each(function (i) {
					var v = $(this).is(":checked");
					page_var.maintenance[i + 3] = v;
				});

				invokePagination();
				initTableCustom($(".table-maintenance"), page_var.maintenance);
				page_var.maintenance_selected = {};
				page_var.maintenance_selected_count = 0;
				//                		$(".b_wrap_mass_editing .btn-group > .btn").addClass("disabled");
				$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);

				$('#filters_modal').modal('hide');
				setTimeout(stickSidebar(), 0);
			}
		}, 'JSON');
	},
	submitFilterFormHandler: function () {
		$('#filters_modal form').submit(function (e) {
			e.preventDefault();
			showLoader(true);
			$(this).ajaxSubmit({
				cache: false,
				method: 'POST',
				dataType: 'JSON',
				success: function (response) {
					if (response.success == true) {
						$('#counter').html(response.count);
						$('.filter-content').replaceWith(response.content.replace(/\\/g, ''));
						$("#table_customize_modal :checkbox").each(function (i) {
							var v = $(this).is(":checked");
							page_var.maintenance[i + 3] = v;
						});

						invokePagination();
						initTableCustom($(".table-maintenance"), page_var.maintenance);
						page_var.maintenance_selected = {};
						page_var.maintenance_selected_count = 0;
						$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);
						if (response.refinements) {
							$(".filter_refinements").html(response.refinements);
							FormHandling.collapseFilters();
						}

						$('#filters_modal').modal('hide');
						stickSidebar();
						initScroller();
						showLoader(false);
					}
				}
			});

		});
	},
	collapseFilters: function () {

		var checkBoxQty = 0;

		$('.refinementBox').each(function () {
			if (!$(this).find('.e_collapse').length) {
				FormHandling.hideOptions($(this), checkBoxQty);
			}
		});

		if (page_var.collapseFiltersInit == true) {
			stickSidebar();
			return;
		}
		page_var.collapseFiltersInit = true;
		$("body").on('click', '.refinementTitle.m_collapsible', function (e) {
			e.preventDefault();
			var self = $(this);
			var btn = self.find(".e_collapse");
			var to_close = btn.hasClass("m_open");

			self.parents(".b_filter").find('.checkbox:has(input:not(:checked))').fadeOut('fast');
			self.parents(".b_filter").find(".e_collapse").removeClass("m_open");

			if (!to_close) {
				self.closest(".refinementBox").find('.checkbox').fadeIn('fast');
				btn.toggleClass("m_open");
			}

			stickSidebar();
		});

		stickSidebar();
	},
	hideOptions: function (obj, checkBoxQty) {
		var totalQty = 0;
		obj.find('.checkbox').each(function () {
			totalQty++;
		});
		if (totalQty > checkBoxQty) {
			var selector = checkBoxQty == 0 ? '.checkbox:has(input:not(:checked))' : '.checkbox:gt(' + (checkBoxQty - 1) + ')';
			obj.find(selector).fadeOut('fast');
			if (!obj.find('.refinementTitle .e_collapse').length) {
				obj.find('.refinementTitle').addClass("m_collapsible").prepend('\
	            <span class="e_collapse">\
					<span class="e_show glyphicon glyphicon-plus"></span>\
					<span class="e_hide glyphicon glyphicon-minus"></span>\
				</span>');
			}
		}
	},
	orderItems: function (obj, column, order) {
		$.notify("Inhoud wordt geladen ... Even geduld aub ...");
		showLoader(true);
		var url = $("#url_for_ordering").length ? $("#url_for_ordering").val() : '/onderhoud/order';
		$.ajax({
			url: url,
			cache: false,
			method: 'POST',
			dataType: 'JSON',
			data: { column: column, order: order },
			success: function (response) {
				if (response.success == true) {
					$.notify("Succes");
					$('#counter').html(response.count);
					$('.filter-content').replaceWith(response.html.replace(/\\/g, ''));

					$('.filter-content th[data-column="' + column + '"] .e_sorted')
						.attr('data-order', order)
						.addClass("active")
						.closest("th").siblings().find(".e_sorted.active").removeClass("active");

					$("#table_customize_modal :checkbox").each(function (i) {
						var v = $(this).is(":checked");
						page_var.maintenance[i + 3] = v;
					});

					page_var.maintenance_selected = {};
					page_var.maintenance_selected_count = 0;
					$(".b_wrap_mass_editing .btn-group > .btn").addClass("disabled");
					$(".b_wrap_mass_editing .count").text('');

					invokePagination();
					initTableCustom($(".table-maintenance"), page_var.maintenance);
					$('#filters_modal').modal('hide');
				}
				stickSidebar();
				initScroller();
				showLoader(false);
			}
		});
	}
}
function invokePagination() {
	var selector = '#maintenance';
	if ($('tbody#elements').length) selector = '#elements';

	$(selector).infinitescroll('destroy'); // Destroy
	$(selector).infinitescroll({ state: { isDestroyed: false, isDone: false } });
	$(selector).infinitescroll({
		navSelector: "ul.pagination",
		nextSelector: ".pagination li:nth-child(3) a",
		itemSelector: '.element',
		debug: false,
		dataType: 'html',
		loading: {
			finishedMsg: 'Alle elementen zijn geladen',
			msgText: 'Elementen laden',
			img: homeUrl + '/img/ajax-loader.gif'
		}
	}, function () {
		stickSidebar();
	});
	$(selector).infinitescroll('bind');
}

function refinementsSide() {

	$("body").on("change", "#filters_modal .refinementBoxIn :checkbox", function (e, params) {
		var v = $(this).is(":checked");

		var i = $(this).closest(".checkbox").index();
		var i_box = $(this).closest(".refinementBox").index();
		var side_box = $(".b_filter").find(".refinementBox").eq(i_box);

		// toggle selection
		if (v) {
			side_box.find(".checkbox").eq(i).find(":checkbox").prop("checked", "checked");
		} else {
			side_box.find(".checkbox").eq(i).find(":checkbox").removeAttr("checked");
		}

		if (typeof params != 'undefined') {
			if (params.update !== undefined) {
				$("#filters_modal .btn.btn-primary").click();
			}
		}


	});

	var requestNumber = 0;
	$("body").on("change", ".b_filter .refinementBoxIn :checkbox", function (e, params) {

		e.preventDefault();
		$('#request_number').val(++requestNumber);
		// showLoader(true);
		var box_class = $(this).parents(".refinementBox").data('id');

		$(this).parents("form").ajaxSubmit({
			cache: false,
			method: 'POST',
			dataType: 'JSON',
			success: function (response) {
				if (response.request_number != requestNumber) {
					return;
				}
				if (response.success != true) {
					// showLoader(false);
					return;
				}
				$('#counter').html(response.count);
				$('.filter-content').replaceWith(response.content.replace(/\\/g, ''));
				$("#table_customize_modal :checkbox").each(function (i) {
					page_var.maintenance[i + 3] = $(this).is(":checked");
				});

				invokePagination();
				initTableCustom($(".table-maintenance"), page_var.maintenance);
				page_var.maintenance_selected = {};
				page_var.maintenance_selected_count = 0;
				$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);
				if (response.refinements) {
					$(".filter_refinements").html(response.refinements);
					FormHandling.collapseFilters();
				}

				$('#filters_modal').modal('hide');
				stickSidebar();
				initScroller();
				$(".refinementBox[data-id='" + box_class + "']").find(".refinementTitle.m_collapsible").trigger('click');
				// showLoader(false);
			}
		});
	}).on("click", ".clear_filters", function (e, params) {
		$('.b_search_q').val('').trigger('keyup');
		$("#filters_modal .refinementBox").find("input:checked").removeAttr("checked").trigger("change");
		$("#filters_modal .btn.btn-primary").click();
	});

	// restore state from session
	$("#filters_modal .refinementBox").each(function (i) {
		$(this).find(":checkbox:checked").trigger("change");
	});

	$("#filters_modal").on("hide.bs.modal", function () {
		filterNormalize();
	});
}

$.fn.serializeObject = function () {
	var o = {};
	var a = this.serializeArray();
	$.each(a, function () {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
}

var PhasingSearch = {
	parentForm: null,
	typeaheadElement: null,
	keypressReg: null,
	optionsList: [],
	existingOptions: null,
	typeaheadSource: function (query, process) {
		if (query.length >= 2) {
			if (this.keypressReg != 38 && this.keypressReg != 40) {
				var tmpText = '';
				this.optionsList = [];
				$.each(this.existingOptions, $.proxy(function (index, item) {
					tmpText = $(item).text();
					if (tmpText.toLowerCase().indexOf(query.toLowerCase()) > -1) {
						this.optionsList.push({
							phasing: tmpText,
							id: $(item).val()
						});
					}
				}, this));
				tmpText = undefined;
				return process(this.optionsList);
			} else if (this.keypressReg == 38 || this.keypressReg == 40) {
				return process(this.optionsList);
			}
		} else {
			this.parentForm.find("select[name='phasing_id']").val('');
			this.parentForm.find('.element-form-display').text('');
		}
	},
	init: function (elementName, options, currentForm) {
		this.parentForm = currentForm;
		this.existingOptions = options;
		this.typeaheadElement = $(elementName)
			.typeahead({
				minLength: 0,
				hint: false,
				highlight: true,
				limit: 30,
				items: 45
			}, {
				name: 'phasing-list',
				displayKey: 'phasing',
				source: $.proxy(function (query, process) {
					this.typeaheadSource(query, process);
				}, this),
				templates: {
					empty: [
						'<p>',
						'Geen bestaande fasering gevonden.',
						'</p>'
					].join('\n')
				}
			});

		this.typeaheadElement.on('typeahead:selected', $.proxy(function (event, selection) {
			this.parentForm.find("select[name='phasing_id']").val(selection.id);
			this.parentForm.find('.element-form-display').text(selection.phasing);
		}, this));

		this.parentForm.on('keyup', '.typeahead', $.proxy(function (event) {
			this.keypressReg = event.which;
		}, this));
	},
	destroy: function () {
		this.parentForm.off('keyup');

		$(this.typeaheadElement).unbind();
		if (this.typeaheadElement != null) {
			this.typeaheadElement.typeahead('destroy');
		}
		this.parentForm = null;
		this.typeaheadElement = null;
		this.keypressReg = null;
		this.existingOptions = null;
		this.optionsList = [];
	}
}

function toggleMaintenanceEditing(form) {
	if (!form) return;

	form.find(".date").datetimepicker({
		format: 'M yyyy',
		autoclose: true,
		language: "nl",
		startView: 3,
		minView: 3,
		maxView: 3,
		todayBtn: true,
		pickerPosition: 'bottom-left'
	});

	if ($(".e_maintenance_form.m_edit .flatpickr-input").length > 0) {
		// Remove all existing flatpickrs
		$(".e_maintenance_form.m_edit .flatpickr-input.input").remove();
	}

	$(".flatpickr.regular-date").flatpickr({
		altInput: true,
		altFormat: "d-m-Y",
		minDate: Date.now()
	});

	$(".flatpickr.regular-date-history").flatpickr({
		altInput: true,
		altFormat: "d-m-Y",
	});

	form.find(".chosen-select.m_single > select:visible").each(function () {
		var deselectable = $(this).closest(".chosen-select").hasClass("m_deselectable");

		$(this).chosen({
			max_selected_options: 1,
			allow_single_deselect: deselectable,
			placeholder_text_single: 'Kies een optie',
			placeholder_text_multiple: 'Kies opties',
			width: '100%'
		});
	});

	form.find(".select2-select.m_single > select:visible").each(function () {
		form.find("select[name='phasing_id']").hide();
		PhasingSearch.init('.typeahead', form.find("select[name='phasing_id'] option"), form);
	});

}

function showLoader(to_show) {
	$('body').toggleClass('show_loader', to_show);
}
$(document).ready(function () {
	$(".slideshow-container").click(function () {
		$("#image_modal").modal('hide');
	});
});
