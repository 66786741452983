(function ($) {

    if (typeof page_var == "undefined") page_var = {}

    $(document).on('click', '.prevent-default', function (e) {
        e.preventDefault();
    });

    $('.date.profit').datetimepicker({
        format: 'yyyy-mm-dd',
        locale: appLocale || 'nl',
    });

    $('.chosen-single-select > select').chosen({
        max_selected_options: 1,
        placeholder_text_single: 'Kies een optie',
        width: '100%'
    });

    var idx = $('.landscape-element-item:visible').length;
    $('.add-landscape-element').on('click', function (e) {
        idx++;
        e.preventDefault();
        var $element  = $($('#landscape-element-template').html().replace(/__idx__/g, -idx));
        $element.find('.chosen-single-select > select').each(function () {
            $(this).chosen({
                max_selected_options: 1,
                placeholder_text_single: 'Kies een optie',
                width: '100%'
            });
        });
        $('#landscape-element-list').prepend($element.fadeIn(600));
    });

    $(document).on('click', '.remove-landscape-element', function (e) {
        e.preventDefault();
        var $button = $(this);
        $button.closest('.landscape-element-item').fadeOut(600, function () {
            $(this).remove();
        });
        var item_id = $button.data('element-id');

        if(item_id > 0) {
            $('<input>').attr({
                type: 'hidden',
                name: 'delete_landscape_element[]',
                value: item_id
            }).appendTo('#landscape-element-list');
        }
    });


    //resize datatable when filters collapse
    function resizeTable() {
        var newHeight = $('.refinements-block').height();
        console.log( window.tableHeight);
        $('div.dataTables_scrollBody').height(newHeight > window.tableHeight ? newHeight : window.tableHeight);
    }

    $(document).on('shown.bs.collapse', '.collapse-item', function () {
        resizeTable();
    });
    $(document).on('hidden.bs.collapse', '.collapse-item', function () {
        resizeTable();
    });

    $(document).on('click', '.clear-filters', function(){
        $('div.dataTables_scrollBody').height(window.tableHeight);
    });
    

})(jQuery);
