/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.VueMultiselect = require('vue-multiselect');
require('vuejs-datepicker');
// import Datepicker from 'vuejs-datepicker';
// window.vuejsDatepicker = Datepicker;

require('blueimp-file-upload');
require('blueimp-load-image');
require('chosen-js');
require('magnific-popup');

require('./lib/bootstrap');
require('./lib/tmpl.min');
require('./lib/typeahead.bundle.min');
require('./lib/flatpickr');

window.form = require('./lib/jquery.form.min');
window.scrollbar = require('./lib/jquery.scrollbar.min');
window.slimscroll = require('./lib/jquery.slimscroll.min');

window.Dropzone = require('./lib/dropzone');
window.datetimepicker = require('./lib/bootstrap-datetimepicker.min');
window.infinitescroll = require('./lib/jquery.infinitescroll');
window.DataTable = require('./lib/datatables.min');

Vue.prototype.$http = window.axios;

import { Tabs, Tab } from 'vue-tabs-component';
import 'es6-promise/auto'

Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i);

files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('vuejs-datepicker', require('vuejs-datepicker').default);
