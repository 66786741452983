/* Maintenance JS */
$(function () {
	// ready
	page_var.maintenance = {};
	page_var.maintenance_selected = {};
	page_var.maintenance_selected_count = 0;
	page_var.notes_count = 0;

	if (typeof customTableParams !== "undefined")
		initTableCustom($(".table-maintenance"), customTableParams);
	/* Infinite scroll set in elements.js */
	if ($("#maintenance").length || $("#profits").length) {
		maintenanceMassStatus();

		$("body").on(
			"click",
			"#maintenance .click-open-notes, #profits .click-open-notes",
			function (e) {
				var button = $(this);
				var modal = $(".maintenanceNotes");

				modal.find("input[name='element_id']").val(button.data("element-id"));
				modal.find("input[name='maintenance_id']").val(button.data("id"));

				if (button.hasClass("e_add")) {
					$.get(
						getMaintenanceNotes +
						"/" +
						button.data("element-id") +
						"/" +
						button.data("id"),
						function (response) {
							console.log(response);
							page_var.notes_count = response.notes_count;
							modal.find(".notes").replaceWith(response.html);
							modal.modal("show");
						}
					);
				} else {
					page_var.notes_count = 0;
					modal.find(".notes").html("");
					modal.modal("show");
				}

				modal.on("hide.bs.modal", function (e) {
					if (page_var.notes_count == 0) return;
					var parent = button.parents("td");
					parent.find(".e_add").addClass("hidden");
					parent
						.find(".notes_number")
						.text(page_var.notes_count)
						.removeClass("hidden");
					modal.off("hide.bs.modal");
				});
			}
		);

		// update note
		$("body").on("click", ".maintenanceNotes .click-save-mnote", function (e) {
			e.preventDefault();
			var form = $(this).closest("form");
			var data = form.serialize();

			form.closest(".e_element_note_form").addClass("m_loading");

			$.post(
				form.attr("action"),
				data,
				function (response) {
					form.closest(".e_element_note_form").removeClass("m_loading m_edit");
					form.find(".form-control").val("");
					$.notify("Notitie opgeslagen", "success");
					$(".maintenanceNotes")
						.find(".notes")
						.replaceWith(response.content);
					page_var.notes_count++;
				},
				"json"
			);
		});
	}

	$("#filters_modal a.toggle-filters").click(function () {
		$('#filters_modal input[type="checkbox"]').removeAttr("checked");
	});
});

function initTableCustom(table, customTableParams) {
	if (!table) table = $(".table-maintenance");
	table.addClass("m_custom");

	// bind events once
	if (page_var.initedTableCustom === undefined) {
		page_var.initedTableCustom = true;
		$("#table_customize_modal :checkbox").on("change", function (e) {
			table = $(".table-maintenance"); // for ajax re-rendering
			var obj = $(this).closest("div");
			var v = $(this).is(":checked");

			var i = obj.index() + 3; // starts from one + 2 cells that are always shown
			table.addClass("m_custom");
			if (v === true) {
				table.attr("data-tcol" + i + "-show", "true");
			} else {
				table.removeAttr("data-tcol" + i + "-show");
			}

			page_var.maintenance[i] = v;
		});

		$("#table_customize_modal").on("hide.bs.modal", function (e) {
			$.post(
				customizeTable,
				{ maintenance_cols: page_var.maintenance },
				function (response) {
					// console.log(response);
				}
			);
		});

		$("#table_customize_modal .e_show_all").on("click", function (e) {
			e.preventDefault();
			$(this)
				.closest(".modal")
				.find(".e_selectors")
				.find(":checkbox")
				.not(":checked")
				.each(function () {
					$(this)
						.prop("checked", "checked")
						.trigger("change");
				});
		});

		$("#table_customize_modal .e_hide_all").on("click", function (e) {
			e.preventDefault();
			$(this)
				.closest(".modal")
				.find(".e_selectors")
				.find(":checkbox:checked")
				.each(function () {
					$(this)
						.removeAttr("checked")
						.trigger("change");
				});
		});
	}

	if (customTableParams !== null) {
		for (var item in customTableParams) {
			if (
				customTableParams[item] == "true" ||
				customTableParams[item] == true
			) {
				table.attr("data-tcol" + item + "-show", true);
				$("#table_customize_modal .e_select_column")
					.eq(item - 3)
					.find(":checkbox")
					.prop("checked", "checked")
					.trigger("change");
			}
		}
		page_var.maintenance = customTableParams;
	} else {
		$("#table_customize_modal .e_select_column").each(function (i) {
			$(this)
				.find(":checkbox")
				.prop("checked", "checked")
				.trigger("change");
			page_var.maintenance[i + 3] = true;
		});
	}
}

$(document).on(
	"click",
	".table-maintenance thead .thead th .e_sorted span",
	function (e) {
		var column = $(this)
			.closest("th")
			.data("column");
		var order = "";
		if ($(this).hasClass("e_asc")) {
			order = "desc";
		} else if ($(this).hasClass("e_desc")) {
			order = "asc";
		}

		FormHandling.orderItems($(this), column, order);
		return false;
	}
);

/*
 * Mass maintenance editing
 */
function maintenanceMassStatus() {
	$("body").on("click", "#maintenance .element", function (e) {
		e.preventDefault();
		var obj = $(this);
		obj.toggleClass("info selected");

		var i = $(this).index();
		if (page_var.maintenance_selected[i] === undefined) {
			page_var.maintenance_selected[i] = obj.data("id");
			page_var.maintenance_selected_count++;
		} else {
			delete page_var.maintenance_selected[i];
			page_var.maintenance_selected_count--;
		}

		obj.find(".e_selected").toggleClass("hide m_open");
		$(".b_wrap_mass_editing .btn-group > .btn").removeClass("disabled");
		$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);
	});

	$(".b_wrap_mass_editing").on("click", ".e_clear", function (e) {
		e.preventDefault();
		$("#maintenance .element.selected")
			.removeClass("info")
			.removeClass("selected");
		$("#maintenance .e_selected")
			.removeClass("m_open")
			.addClass("hide");
		page_var.maintenance_selected = [];
		page_var.maintenance_selected_count = 0;
		$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);
	});

	$(".b_wrap_mass_editing").on("click", ".e_pick_all", function (e) {
		e.preventDefault();
		var i = 0;
		$("#maintenance .element")
			.each(function () {
				i = $(this).index();
				page_var.maintenance_selected[i] = $(this).data("id");
				page_var.maintenance_selected_count++;
				$(this)
					.find(".e_selected")
					.removeClass("hide")
					.addClass("m_open");
			})
			.removeClass("info selected")
			.addClass("info selected");
		$(".b_wrap_mass_editing .count").text(page_var.maintenance_selected_count);
	});

	$(".b_wrap_mass_editing").on("click", "li.e_change_status", function (e) {
		e.preventDefault();

		action = $(this).data("status");

		var items = [];
		for (prop in page_var.maintenance_selected) {
			items.push(page_var.maintenance_selected[prop]);
		}
		if (items.length > 0) {
			$.post(
				postStatusUpdate,
				{ status: action, items: items },
				function (response) {
					if (response.success == true) {
						$.notify(response.content);
						$.notify("Reloading...");
						$(".b_wrap_mass_editing .e_clear").click();
						$("#filters_modal form").submit();
					} else {
						$.notify(response.content, "warning");
					}
				},
				"json"
			);
		}
	});
	$("body").on("click", "#maintenance .element a", function (e) {
		e.stopPropagation();
	});
}
