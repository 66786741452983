/* Admin page js */
$(function () {
	// Submit button action for group and organisation editing modals
	$("#rolesEditModal .e_submit, #organisationEditModal .e_submit").on("click", function (e) {
		e.preventDefault();
		var form = $(this).closest(".modal").find("form");
		form.find(".error_message").addClass('hidden');

		// This only sends the true value all the other will be set false in the back-end
		form.ajaxSubmit({
			cache: false,
			method: 'POST',
			dataType: 'JSON',
			success: function (response) {
				if (response.success == true) {
					window.location = response.redirect_url;
				} else {
					form.find(".error_message").html(response.error).removeClass('hidden');
				}
			}
		});
	});

	// Delete button for the group and organisation editing modals
	$("#rolesEditModal .delete_btn, #organisationEditModal .delete_btn").on("click", function (e) {
		e.preventDefault();
		var form = $(this).closest(".modal").find("form");
		form.find(".error_message").addClass('hidden');
		var button = $(this);

		$.ajax({
			method: "DELETE",
			url: button.data('url'),
			data: { id: form.find('input[name=id]').val() },
			dataType: "json"
		}).done(function (response) {
			if (response.success == true) {
				window.location = response.redirect_url;
			} else {
				form.find(".error_message").html(response.error).removeClass('hidden');
			}
		});
	});

	/*
	 * forms approval
	 */
	if ($(".b_forms_maintenance").length) {
		initInfinitescroll();


		$(".b_wrap_forms_maintenance > .nav > li > a").on("shown.bs.tab", function () {
			initInfinitescroll(1);
		});

		/*
		 * select multiple items maintenance form status
		 * #TODO
		 */
		$(".b_wrap_forms_maintenance").on("change", ".b_forms_maintenance__el .e_check input", function (e) {
			e.preventDefault();
			var obj = $(this).closest(".b_forms_maintenance__el");
			var cur = $(this);

			obj.toggleClass("panel-info panel-default");

			if (page_var.forms_mass_edit_count === undefined) page_var.forms_mass_edit_count = 0;
			if (cur.is(':checked')) {
				page_var.forms_mass_edit_count++;
			} else {
				page_var.forms_mass_edit_count--;
			}

			if (page_var.forms_mass_edit_count >= 1) {
				$(".b_mass_form_edit .count").text(page_var.forms_mass_edit_count);
				$(".b_mass_form_edit .dropdown-menu li .e_actions").removeClass("disabled")
			} else {
				$(".b_mass_form_edit .count").text('');
				$(".b_mass_form_edit .dropdown-menu li .e_actions").addClass("disabled")
			}

		});
		/*
		 * check/uncheck form status on panel heading
		 */
		$(".b_wrap_forms_maintenance").on("click", ".b_forms_maintenance__el .panel-heading", function (e) {
			var checkbox = $(this).find(".e_check input");
			if (checkbox.is(":checked")) {
				checkbox.removeAttr("checked");
			} else {
				checkbox.prop("checked", "checked");
			}
			checkbox.trigger("change");
		});

		/*
		 * Forms mass uncheck
		 */
		$(".b_mass_form_edit").on("click", ".e_select, .e_deselect", function (e) {
			var checkboxes = $(".b_wrap_forms_maintenance > .tab-content > .tab-pane.active .b_forms_maintenance__el .e_check input");

			if ($(this).hasClass("e_deselect")) {
				checkboxes.removeAttr("checked");
			} else {
				checkboxes.prop("checked", "checked");
			}
			checkboxes.trigger("change");
		});

		/*
		 * update maintenance form status
		 */
		$(".b_wrap_forms_maintenance").on("click", ".b_forms_maintenance__el .controls .btn", function (e) {
			var btn = $(this);
			if (btn.hasClass("no-action")) return true;

			e.preventDefault();
			e.stopPropagation();
			var obj = $(this).closest(".b_forms_maintenance__el");
			if (btn.hasClass("active")) return;


			if ($(this).hasClass("e_delete")) {
				if (!confirm("Weet je het zeker?")) return false;

				obj.addClass("m_loading");

				$.ajax({
					url: obj.data("action"),
					type: 'DELETE',
					success: function (response) {
						obj.removeClass("m_loading");
						if (response.success == true) {
							$(".b_wrap_forms_maintenance")
								.html(response.html);

							initInfinitescroll(1);
						} else {
							$.notify(response.content, "warning");
						}
					},
					dataType: 'json'
				});
			} else {

				var action = true;
				if ($(this).hasClass("e_approve")) {
				} else if ($(this).hasClass("e_cancel")) {
					action = false;
				} else {
					return $.notify("Unknown action", "warning");
				}

				obj.addClass("m_loading");

				$.post(obj.data("action"), { action: action }, function (response) {
					obj.removeClass("m_loading");
					if (response.success == true) {
						$(".b_wrap_forms_maintenance")
							.html(response.html);

						initInfinitescroll(1);
					} else {
						$.notify(response.content, "warning");
					}
				}, "json");
			}

		});

	}

	// Show the group editing modal and fill it's information
	$('#rolesEditModal').on('show.bs.modal', function (event) {
		var button = $(event.relatedTarget);
		var modal = $(this);

		modal.find('input[name="permissions[]"]').prop('checked', false);
		modal.find('input[name=id]').val(0);
		modal.find('input[name=name]').val('');
		modal.find(".modal-content").removeClass("edit");
		modal.find(".modal-content").addClass("create");

		if (parseInt(button.data('id')) <= 0) return;
		$.get(button.data('url'), { id: button.data('id') }, function (response) {
			if (!response.roles) return;

			modal.find('input[name=id]').val(response.roles.id);
			modal.find('input[name=name]').val(response.roles.name);

			var permissionObject = JSON.parse(response.roles.permissions);
			$.each(permissionObject, function (index, value, key) {
				modal.find('input[name="permissions[]"][value="' + index + '"]').prop('checked', true);
			});
			modal.find(".modal-content").removeClass("create");
			modal.find(".modal-content").addClass("edit");
		}, 'json');
	});

	// Show the group editing modal and fill it's information
	$('#organisationEditModal').on('show.bs.modal', function (event) {
		var button = $(event.relatedTarget);
		var modal = $(this);

		modal.find('input[name=id]').val(0);
		modal.find('input[name=organisation]').val('');
		modal.find('input[name=map]').val('');
		modal.find('.districts .fields').empty();
		modal.find('.locations .fields').empty();
		modal.find(".modal-content").removeClass("edit");
		modal.find(".modal-content").addClass("create");

		if (parseInt(button.data('id')) <= 0) return;

		// Fill form fields with proper information
		$.get(button.data('url'), { id: button.data('id') }, function (response) {
			if (!response.organisation) return;

			modal.find('input[name=id]').val(response.organisation.id);
			modal.find('input[name=organisation]').val(response.organisation.organisation);
			modal.find('input[name=map]').val(response.organisation.map);
			modal.find('input[name=collective]').prop('checked', response.organisation.collective);

			var show_menu = ['elements', 'grasslands', 'tables', 'subsidies', 'maintenance', 'costs_profits', 'contracts', 'brief', 'profits', 'forms'];

			$.each(show_menu, function (index, value) {
				modal.find('input[name=show_menu_' + value + ']').prop('checked', response.organisation['show_menu_' + value]);
			});

			// Create the district form fields
			$.each(response.organisation.districts, function (index, district) {
				var districtTemplate = $('.form-templates .district-template').clone();
				districtTemplate.find('input[name="districts[][district]"]').val(district.district);
				districtTemplate.appendTo('.districts .fields');

				// Create the locations for this district
				$.each(district.locations, function (index, location) {
					var locationTemplate = $('.form-templates .location-template').clone();
					locationTemplate.find('input[name="locations[][location]"]').val(location.location);
					locationTemplate.appendTo('.locations .fields');
				});
			});

			modal.find(".modal-content").removeClass("create");
			modal.find(".modal-content").addClass("edit");
		}, 'json');
	});
});

function getBusinesses(organisation_id, business_id) {
	var $select = $('#businesses-list').html('');
	$.get('/beheer/businesses/' + organisation_id, function (businesses) {
		if (businesses.length > 0) {
			$select.append($('<option></option>'));
			$.each(businesses, function (index, business) {
				var $option = $('<option></option>').val(business.id).text(business.name);
				if (business_id > 0 && business_id == business.id) {
					$option.prop('selected', true);
				}
				$select.append($option)
			});
		}
	});
}

/**
 * Get all owners for an organisation
 * @param  {[type]} organisation_id [description]
 * @param  {[type]} owner_id        [description]
 */
function getOwners(organisation_id, owner_id) {
	var $select = $('#owners-list').html('');
	$.get('/owners', function (owners) {
		if (owners.length > 0) {
			$select.append($('<option></option>'));
			$.each(owners, function (index, owner) {
				var $option = $('<option></option>').val(owner.id).text(owner.owner);
				if (owner_id > 0 && owner_id == owner.id) {
					$option.prop('selected', true);
				}
				$select.append($option)
			});
		}
	});
}

function getCompanies(organisation_id, company_id) {
	var $select = $('#companies-list').html('');
	$select.append('<option value="0"></option>');
	$.get($('#get-companies-url').val().replace('__organisation_id__', organisation_id), function (companies) {
		if (companies.length > 0) {
			$.each(companies, function (index, company) {
				var $option = $('<option></option>').val(company.id).text(company.executed_by);
				if (company_id > 0 && company_id == company.id) {
					$option.prop('selected', true);
				}
				$select.append($option)
			});
		}
	});
}

/*
 * infinite scroll
 * custom one
 */
function initInfinitescroll(reinit) {

	if ($(".b_wrap_forms_maintenance .tab-pane.active .pagination:visible").length) {
		page_var.pagination_position = $(".b_wrap_forms_maintenance .tab-pane.active .pagination").offset().top;
	} else {
		page_var.pagination_position = 0;
	}

	if (reinit) return;
	var buffer = 200;

	$(window).scroll(function () {
		if (page_var.pagination_position > 0) {

			if (page_var.s_t + page_var.w_h + buffer > page_var.pagination_position) {
				// buffer is buffer
				$(".b_wrap_forms_maintenance .tab-pane.active .pagination > li.active")
					.next().find("a").click();
			}
		}
	});

	$(".b_wrap_forms_maintenance").on("click", ".b_forms_maintenance + .pagination > li a", function (e) {
		e.preventDefault();
		var link = $(this);
		var type = link.closest(".tab-pane").data("type");
		var obj = $(".b_wrap_forms_maintenance .b_forms_maintenance--" + type).find(".b_forms_maintenance");

		if (link.closest("li").index() <= link.closest("li").siblings(".active").index()) return;

		if (page_var.b_forms_maintenance_pagination) return;
		page_var.b_forms_maintenance_pagination = true;
		$.notify("Loading...");

		$.get(link.attr("href"), function (response) {
			page_var.b_forms_maintenance_pagination = false;
			var new_forms = $(response).find(".b_forms_maintenance--" + type);

			link.closest(".pagination").siblings(".b_forms_maintenance")
				.append(new_forms.find(".b_forms_maintenance__el"));

			link.closest(".pagination")
				.replaceWith(new_forms.find(".pagination"));

			obj.siblings(".pagination").find("li")
				.slice(0, obj.siblings(".pagination").find("li.active").index())
				.addClass("disabled");
		})
	});

}
