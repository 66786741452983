<template>
<div>
    <div class="legenda">
        <h4>Legenda</h4>
        <button type="button" v-on:click="interact('Point', 'asterisk')"><span class="glyphicon glyphicon-asterisk" aria-hidden="true"></span>&nbsp;Horst/nest</button><br />
        <button type="button" v-on:click="interact('Point', 'star')"><span class="glyphicon glyphicon-star" aria-hidden="true"></span>&nbsp;Burcht/grondhol</button><br />
        <button type="button" v-on:click="interact('Point', 'tree')"><span class="glyphicon glyphicon-tree-deciduous" aria-hidden="true"></span>&nbsp;Holle boom</button><br />
        <button type="button" v-on:click="interact('Point', 'leaf')"><span class="glyphicon glyphicon-leaf" aria-hidden="true"></span>&nbsp;Flora</button><br />
        <button type="button" v-on:click="interact('Point',  'pushpin')"><span class="glyphicon glyphicon-pushpin" aria-hidden="true"></span>&nbsp;</button><br />

        <h4>Tekenen</h4>
        <div class="row">
            <button type="button" v-on:click="interact('LineString', '#FF4E50')" class="text-danger"><span class="glyphicon glyphicon-minus" style="color:#FF4E50" aria-hidden="true"></span></button>
            <button type="button" v-on:click="interact('Polygon', '#FF4E50')" class="text-danger"><span class="glyphicon glyphicon-unchecked" style="color:#FF4E50" aria-hidden="true"></span></button><br />
        </div>
        <div class="row">
            <button type="button" v-on:click="interact('LineString', '#F9D423')" class="text-warning"><span class="glyphicon glyphicon-minus" style="color:#F9D423" aria-hidden="true"></span></button>
            <button type="button" v-on:click="interact('Polygon', '#F9D423')" class="text-warning"><span class="glyphicon glyphicon-unchecked" style="color:#F9D423" aria-hidden="true"></span></button><br />
        </div>
    </div>

    <div ref="form-map-root" id="form-map-root"></div>
</div>
</template>

<script>
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

import Draw from 'ol/interaction/Draw';
import { Snap, Modify } from 'ol/interaction';
import { shiftKeyOnly } from 'ol/events/condition';

import { Circle, Text, Fill, Stroke, Style, Icon } from 'ol/style';

import {
    register
} from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';

import 'ol/ol.css'


export default {
    name: 'FormMap',
    components: {},
    props: {
        // geoJson: Object,
        element: Object,
        // organisation: Object
    },
    data() {
        return {
            draw: {
                interaction: undefined,      
                modifyDraw: undefined,
                snap: undefined
            },
            drawLayer: undefined,
            map: undefined
        }
    },
    mounted() {
        console.log(this.element)

        var feature = {
                type: 'Feature',
                properties: {
                    text: this.element.common_map_name,
                },
                id: this.element.id,
                geometry: JSON.parse(this.element.geometry)
        };

        // a new vector layer is created with the feature
        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: new GeoJSON().readFeatures(feature),
            }),
        });

        this.drawLayer = new VectorLayer({
            title: 'DrawLayer',
            source: new VectorSource({
                wrapX: false
            }),
        });

        this.drawLayer.setStyle(function(feature) {
		    return new Style({
                stroke: new Stroke({
                    color: 'rgb(29, 1, 139)',
                    width: 2
                }),
            })
        });


        // Default extent if there is no feature extent
        const extent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];

        // Dutch projection
        const projection = new Projection({
            code: 'EPSG:28992',
            units: 'm',
            extent
        });
        proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs');
        proj4.defs('urn:x-ogc:def:crs:EPSG:28992', proj4.defs('EPSG:28992'));
        proj4.defs('http://www.opengis.net/gml/srs/epsg.xml#28992', proj4.defs('EPSG:28992')); // Used by geoserver
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
        register(proj4);

        this.map = new Map({
            target: this.$refs['form-map-root'],
            layers: [
                // Default opelayers map
                new TileLayer({
                    source: new OSM()
                }),
                vectorLayer,
                this.drawLayer
            ],

            view: new View({
                zoom: 0,
                projection,
                center: [197800, 454050],
                zoom: 5
            }),
        })

        // Zoom to element
        if (vectorLayer.getSource().getExtent()) {
            this.map.getView().fit(vectorLayer.getSource().getExtent(), {minResolution: 1});
        }

    },
    methods: {
        /**
        * Draw a redline of the given GeometryType
        * @param  type="Polygon" The GeometryType
        */
        interact(type, setting = 'leaf') {
            // Clear previous drawings
            // this.map.removeInteraction(this.draw.modifyDraw);
            // this.map.removeInteraction(this.draw.snap);
            this.map.removeInteraction(this.draw.interaction);
            this.draw = {
                interaction: undefined,      
                modifyDraw: undefined,
                snap: undefined
            }

            this.draw.interaction = new Draw({
                source: this.drawLayer.getSource(),
                type: /** @type {ol.geom.GeometryType} */ (type),
                freehandCondition: shiftKeyOnly,
                // If its a point it is a icon
                style: type === 'Point' ? new Style({
                    image: new Icon({
                        anchor: [0.5, 46],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: `/img/icons/${setting}.png`,
                    })
                }) : undefined
            });

            // When drawing set the icon
            this.draw.interaction.on("drawend", event => {
                var feature = event.feature;

                if (type === 'Point') {
                    feature.setStyle(
                        new Style({
                            image: new Icon({
                                anchor: [0.5, 20],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'pixels',
                                src: `/img/icons/${setting}.png`,
                            })
                        })
                    )
                } else {
                    feature.setStyle(
                        new Style({
                            stroke: new Stroke({
                                color: setting,
                                width: 2
                            }),
                        })
                    )
                }
            });

            this.draw.modifyDraw = new Modify({
                source: this.drawLayer.getSource()
            });

            // And add the interaction to the map
            this.map.addInteraction(this.draw.modifyDraw);
            this.map.addInteraction(this.draw.interaction);

            // Create a snap interaction
            this.draw.snap = new Snap({
                source: this.drawLayer.getSource()
            });

            // And add the interaction to the map
            this.map.addInteraction(this.draw.snap);
        }
    },
}

</script>

<style>
#form-map-root {
    height: 500px;
    width: 80%;
    float: right;
}
.legenda{
    width: 20%;
    float: left;
    position: absolute;
}
</style>
