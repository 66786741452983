<template>
    <form @submit.prevent="handleSubmit">
        <fieldset>
            <h3>Element maken</h3>
            <div v-for="field in filteredFields" :key="field.name" class="row">
                <div class="col-sm-12">
                    <div v-if="field.type === 'select'">
                        <label :for="field.name">{{ field.label }}</label>
                        <select v-model="element[field.model]" :name="field.name" :required="field.required" class="form-control">
                            <option v-for="option in getOptions(field)" :key="option.id || option.value" :value="option.id || option.value">
                                {{ option.name || option.label }}
                            </option>
                        </select>
                    </div>

                    <div v-else-if="field.type === 'multi-select'">
                        <label :for="field.name">{{ field.label }}</label>
                        <select v-model="element[field.model]" :name="field.name" multiple class="form-control">
                            <option v-for="option in getOptions(field)" :key="option.id || option.value" :value="option.id || option.value">
                                {{ option.name || option.label }}
                            </option>
                        </select>
                    </div>
                    
                    <div v-else-if="field.type === 'number'">
                        <label :for="field.name">{{ field.label }}</label>
                        <input type="number" :placeholder="field.label" v-model="element[field.model]" :name="field.name" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="button-row">
                <button type="button" class="btn btn-default" @click="cancel">Cancel</button>
                <input type="submit" class="btn btn-success pull-right" value="Opslaan" />
            </div>
        </fieldset>
    </form>
</template>

<script>
export default {
    props: {
        formStructure: {
            type: Array,
            required: true
        },
        organisationId: {
            type: Number,
            required: true
        },
        element: {
            type: Object,
            required: true
        },
        sortedElementTypes: {
            type: Array,
            required: true
        },
        elementsData: {
            type: Object,
            required: true
        }
    },
    computed: {
        filteredFields() {
            return this.formStructure.filter(field => field.organisations === "*" || field.organisations.includes(this.organisationId));
        }
    },
    methods: {
        getOptions(field) {
            if (Array.isArray(field.options)) {
                return field.options;
            } else if (typeof field.options === 'object') {
                return Object.entries(field.options).map(([key, value]) => ({
                    id: key,
                    name: value
                }));
            }
            return this[field.options];
        },
        handleSubmit() {
            this.$emit('submit', this.element);
        },
        cancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<style scoped>
.row {
    margin-bottom: 15px;
}

.button-row {
    margin-top: 15px;
}
</style>
