
$(function() {

    var $table = $('#contracts-panel');

    if ($table.length > 0) {
        new Vue({
            el: '#contracts-panel',
            components: {
                Multiselect: window.VueMultiselect.default
            },
            data: {
                vModelLike: "",
                options: [],
                value: null,
                owner: undefined,
                endDate: new Date(2021, 11, 31)
            },
            beforeMount: function() {},
            methods: {
                initializeDatatables: function(owner = undefined) {
                    // Update the owner, if it is set
                    if (owner) {
                        this.owner = owner;
                    }

                    var dataTable = $('#contract-elements-table').DataTable({
                        destroy: true,
                        ajax: elementsGetByOwnerUrl + '?id=' + this.owner.id,
                        dom: 'Bt',
                        select: {
                            style: 'multi+shift'
                        },
                        rowId: 'id',
                        scroller: {
                            loadingIndicator: true
                        },
                        buttons: [
                            'selectAll',
                            'selectNone'
                        ],
                        language: {
                            buttons: {
                                selectAll: "<a class='btn btn-default'>Selecteer alle items</a>",
                                selectNone: "<a class='btn btn-default'>Selectie opheffen</a>"
                            }
                        },
                        scrollY: '50vh',
                        scrollX: '50vh',
                        serverSide: false,
                        order: [
                            [2, 'asc']
                        ],
                        columns: [{
                                orderable: false,
                                className: 'select-checkbox',
                                render: function(data, type, row) {
                                    return '';
                                }
                            },
                            {
                                orderable: false,
                                render: function(data, type, row) {
                                    return '<a href="' + cookUrl.replace('_id_', row.id) +  '" target="_blank">' +
                                        '<span class="glyphicon glyphicon-globe"></span>' +
                                        '</a>'
                                }
                            },
                            {
                                data: 'lsn',
                                name: 'lsn',
                                render: function(data, type, row) {
                                    return '<a href="' + elementUrl.replace('__id__', row.id) + '">' + data + '</a>'
                                }
                            },
                            { data: 'owner.owner', name: 'owners.owner', defaultContent: '' },
                            { data: 'location.location', name: 'locations.location', defaultContent: '' },
                            { data: 'elementtype.element_type_' + appLocale, name: 'element_type.element_type_' + appLocale },
                            { data: 'objective.objective_' + appLocale, name: 'objectives.objective_' + appLocale, defaultContent: '' },
                            { data: 'diameter.diameter', name: 'diameters.diameter', defaultContent: '' },
                            {
                                data: 'length',
                                name: 'length',
                                render: function(data, type, row) {
                                    return data + ' m';
                                }
                            },
                            {
                                data: 'area',
                                name: 'area',
                                render: function(data, type, row) {
                                    return data + ' m<sup>2</sup>';
                                }
                            },
                            { data: 'number_in_row', name: 'number_in_row' },
                            {
                                data: 'flora',
                                name: 'flora',
                                orderable: false,
                                render: function(data, type, row) {
                                    var floraArr = [];
                                    $.each(data, function(index, obj) {
                                        if (floraArr.indexOf(obj['flora_' + appLocale]) < 0) {
                                            floraArr.push(obj['flora_' + appLocale]);
                                        }
                                    });
                                    return floraArr.join();
                                }
                            },
                            {
                                data: 'fauna',
                                name: 'fauna',
                                orderable: false,
                                render: function(data, type, row) {
                                    var faunaArr = [];
                                    $.each(data, function(index, obj) {
                                        if (faunaArr.indexOf(obj['fauna_' + appLocale]) < 0) {
                                            faunaArr.push(obj['fauna_' + appLocale]);
                                        }
                                    });
                                    return faunaArr.join();
                                }
                            }

                        ],
                        fnInfoCallback: function(oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                            $('#counter').text(iTotal);
                            if (typeof window.tableHeight == 'undefined') {
                                window.tableHeight = $('div.dataTables_scrollBody').height();
                            }
                        }
                    });
                },
                createContractFromSelection: function() {
                    // Get the selected elements
                    var dataTable = $('#contract-elements-table').DataTable();
                    var selectedElements = dataTable.rows({ selected: true }).data();
                    var selectedElementsIds = dataTable.rows({ selected: true }).ids();

                    // Post them to the URL for creating a new contract
                    $.post(contractStoreUrl, {
                        'elements': selectedElementsIds.toArray(),
                        'end_date': this.endDate.toLocaleDateString("en-US"),
                        'owner_id': this.owner.id
                    }, function(data) {
                        console.log(data);
                        if (data.success) {
                            // Post successful, open the newly created contract
                            window.location.href = data.contract_url;
                        }
                    });
                },
                nameWithAddress: function(options) {
                    return `${options.owner} - ${options.street}, ${options.city}`;
                }
            }
        });
    }
})