$(function () {
    var $table = $('#costs-profits');

    if ($table.length > 0) {

        var dataTable = $('#costs-profits-register').DataTable({
            ajax: costsProfitsTableByOwnerUrl,
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
            scrollX: '80vh',
            serverSide: false,
            order: [[1, 'asc']],
            columns: [
                {
                    orderable: false,
                    render: function (data, type, row) {
                        var elType = row.element.elementtype['element_type_' + appLocale] || '';
                        return '<a href="' + mapUrl.replace('__lsn__', row.element.lsn).replace('__type__', elType.toLowerCase().replace(/\+|\s+|\\|\/|\-/g, '')) + '" target="_blank">' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>'
                    }
                },
                {
                    data: 'element.lsn',
                    name: 'element.lsn',
                    render: function (data, type, row) {
                        return '<a href="' + elementUrl.replace('__id__', row.element.id) + '">' + data + '</a>'
                    }
                },
                {data: 'element.owner.owner', orderable: false, defaultContent: ''},
                {data: 'element.elementtype.element_type_' + appLocale, orderable: false, defaultContent: ''},
                {
                    data: 'element.objective.objective_' + appLocale,
                    name: 'element.objective.objective_' + appLocale,
                    orderable: false,
                    defaultContent: ''
                },
                {data: 'maintenancetype.maintenance_type_' + appLocale, orderable: false, defaultContent: ''},
                {data: 'year', name: 'year', defaultContent: ''},
                {data: 'element.shape.shape_' + appLocale, defaultContent: ''},
                {data: 'unit.unit', orderable: false, defaultContent: ''},
                {data: 'calculation_value', name: 'calculation_value', defaultContent: ''},
                {
                    render: function (data, type, row) {
                        if(row.gain === undefined){
                            row.gain = 0;
                        }
                        return '<input type="number" class="gain" name="maintenance[' + row.id + '][gain]" value="' + row.gain + '" />';
                    }
                },
                {
                    render: function (data, type, row) {
                        return '<input type="number" class="gain-tons" name="maintenance[' + row.id + '][gain_tons]" value="' + row.gain / 1000 + '" disabled />';
                    }
                }

            ],
            fnInfoCallback: function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                }
            }
        });

        new Vue({
            el: '#app-costs-profits',
            data: {
                vModelLike: "",
                owner: undefined,
                year: undefined,
                gain: 0,
                gainTons: 0,
                table: $('#costs-profits-register')
            },
            methods: {
                updateGain: function () {
                    $.boomGain = this.gain;
                    $.ajax({
                        url: costProfitsGetGainUrl + '?owner_id=' + this.owner + '&year=' + this.year,
                    }).done(function (result) {
                        $('input[name="gain"]').val(result.gain);
                        $('input[name="gain_tons"]').val(result.gain/1000);
                    });
                },
                isTableHidden: function () {
                    return this.owner && this.year ? false : true;
                    // return false;
                }
            },
            watch: {
                owner: function (val, oldVal) {
    				// if (typeof oldVal === 'undefined') {
    				// 	this.initializeDatatables();
    				// 	return;
    				// }
                    if(this.owner && this.year) {
                        this.updateGain();
    				    dataTable.ajax.url(costsProfitsTableByOwnerUrl + '?owner=' + val + '&year=' + this.year).load();
                    }
    			},
                year: function (val) {
                    if(this.owner && this.year) {
                        this.updateGain();
                        dataTable.ajax.url(costsProfitsTableByOwnerUrl + '?owner=' + this.owner + '&year=' + val).load();
                    }
                },
                gain: function (val) {
                    this.gainTons = val/1000;
                }
            }
        });

        // Auto change the tons input
        $(document).on('change', 'input.gain', function (e) {
            var gain = $(this).val();
            $(':input.gain-tons:eq(' + $(':input.gain').index(this) +')').val(gain/1000);
        });
    }
});
