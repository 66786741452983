$(function(){
	var $table = $('#businessplans-table');

    if ($table.length > 0) {

        var dataTable =$table.DataTable({
            ajax: businessplansTableUrl,
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
            scrollX: '50vh',
            serverSide: true,
            order: [[2, 'asc']],
            columns: [
	            {
                    data: 'id', name: 'id', orderable: false, render: function (data, type, row) {
                    return '<div class="pull-right">' +
                        '<a href="' + businessplanUrl.replace('__id__', row.id) + '" class="btn btn-warning btn-xs" style="margin-right: 5px;">' +
                        '<span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>' +
                        '</a>' +
                        '<a href="' + businessplanDeleteUrl.replace('__id__', row.id) + '" class="btn btn-danger btn-xs" style="margin-right: 5px;">' +
                        '<span class="glyphicon glyphicon-remove" aria-hidden="true"></span>' +
                        '</a>' +
                        '<a href="' + businessplanActivateUrl.replace('__id__', row.id) + '" class="btn btn-success btn-xs" style="margin-right: 5px;">' +
                        '<span class="glyphicon glyphicon-check" aria-hidden="true"></span>' +
                        '</a>' +
                        '</div>';
                    }
                },
				{
            		data: 'business.active_business_plan_id',
            		name: 'business.active_business_plan_id',
            		defaultContent: '',
            		render: function(data, type, row) {
            			if(row.id == row.active_business_plan_id) {
            				return '<span class="glyphicon glyphicon-check" aria-hidden="true"></span>';
            			} else {
            				return '';
            			}
            		}
            	},
            	{
            		data: 'business_name',
            		name: 'business_name',
            		defaultContent: ''
            	},
            	{
            		data: 'bp_id',
            		name: 'bp_id',
            		defaultContent: ''
            	},
            	{
            		data: 'bp_name',
            		name: 'bp_name',
            		defaultContent: ''
            	},
            	{
            		data: 'hourly_rate',
            		name: 'hourly_rate',
            		defaultContent: ''
            	},
            	{
            		data: 'description',
            		name: 'description',
            		defaultContent: ''
            	}
            ],
            fnInfoCallback: function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                    console.log(window.tableHeight);
                }
            }
        });
    }
})
