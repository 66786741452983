$(function () {

    var $table = $('#subsidies-table');

    if ($table.length > 0) {
        var dataTable = $table.DataTable({
                ajax: subsidiesGetTableUrl,
                dom: 't',
                scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
                scrollX: '50vh',
                serverSide: true,
                order: [[1, 'asc']],
                columns: [
                {
                    orderable: false, render: function (data, type, row) {
                        if(row.elementtype != null) {
                            if(row.organisation_id == 10) {
                                return '<a target="_blank" href="' + vgwUrl + '\\' + row.id + '"' +
                                    '<span class="glyphicon glyphicon-globe"></span>' +
                                    '</a>';
                            }
                            else {
                                return '<a target="_blank" href="' + cookUrl.replace('_id_', row.id) + '"' +
                                    '<span class="glyphicon glyphicon-globe"></span>' +
                                    '</a>';
                            }
                        } else {
                            return '';
                        }
                    }
                },
                {data: 'lsn', name: 'lsn', render: function (data, type, row) {
                    return '<a href="'+elementUrl.replace('__id__', row.id)+'">'+data+'</a>'
                }},
                {data: 'location.location', name: 'locations.location', defaultContent:''},
                {data: 'elementtype.element_type_'+appLocale, name: 'element_type.element_type_'+appLocale, defaultContent:''},
                {data: 'subsidy.workarea', name: 'subsidy.workarea', defaultContent:''},
                {data: 'subsidy.pakketnaam', name: 'subsidy.pakketnaam', defaultContent:''},
                {data: 'owner.owner', name: 'owners.owner', defaultContent:''},
                {data: 'subsidy.deeln_naam', name: 'subsidy.deeln_naam', defaultContent:''},
                {data: 'subsidy.manager', name: 'subsidy.manager', defaultContent:''},
                {data: 'subsidy.periodstrt', name: 'subsidy.periodstrt', defaultContent:''},
                {data: 'subsidy.periodend', name: 'subsidy.periodend', defaultContent:''}

            ],
                fnInfoCallback: function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                }
            }
        });


        new Vue({
            el: '#app-subsidies',
            created: function () {
                this.getFilters();
            },
            data: {
                filters: [],
                total: 0,
                timer: 0,
                search: null
            },
            methods: {
                searchTable: function (clear = '') {
                    if (clear == 'clear') {
                        this.search = "";
                    }
                    dataTable.columns(1).search(this.search).draw();
                    this.getFilters();
                },
                getFilters: function (updateTable) {
                    var url = subsidiesGetFiltersUrl;
                    if(this.search){
                        url = url+'?columns[1][search][value]='+this.search;
                    }
                    this.$http.get(url).then(function(response){
                        this.filters = response.data;
                        if(updateTable) {
                            dataTable.ajax.reload();
                        }
                    }, function(){
                        console.log('Get filters error!');
                    });
                },
                postFilters: function (filters) {
                    // clears the timer on a call so there is always x seconds in between calls
                    clearTimeout(this.timer);

                    // if the timer resets before it hits 150ms it will not run
                    this.timer = setTimeout(function(){
                        this.$http.post(subsidiesPostFiltersUrl, {filters: filters, search: this.search}).then(function (response) {
                            this.getFilters(true);
                        }, function () {
                            console.log('Post filters error!');
                        });
                    }.bind(this), 1600);
                },
                resetFilters: function () {
                    this.search = null;
                    dataTable.columns(1).search('');
                    this.postFilters([]);
                },
                changeFilters: function () {
                    this.postFilters(this.filters);
                },
                collapse: function (index, filter) {
                    filter.collapsed = !filter.collapsed;
                    this.$http.post(subsidiesCollapseFilterUrl, {filter: {index: index, collapsed: filter.collapsed}}).then(function(response){
                        console.log(index + 'Collapse state ' + filter.collapsed );
                    }, function(){
                        console.log('Post filters error!');
                    });
                }
            }
        });

    }

});
