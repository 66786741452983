<template>
    <div>
        <div class="print-form">
            <form name="print" class="print-form" @submit.prevent="print()">
                <h4>Print Formulier</h4>
                <fieldset>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="text-form-group">
                                <label for="title">Titel</label>
                                <input type="text" class="form-control" v-model="title" id="title">
                            </div>
                        </div>
                    </div>

                    <!--<div class="row" *ngIf="locations">
                <div class="col-sm-12">
                    <form-field>
                        <select placeholder="Locatie" [(ngModel)]="location" name="location" [(value)]="location" @change="selectLocation()">
                            <option *ngFor="let l of locations" [value]="l">{{ l.name }}</option>
                        </select>
                    </form-field>
                </div>
            </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <label for="format">Papier Formaat</label>
                            <select class="form-control" id="format" v-model="printPaperSize"
                                @change="zoomMapToScale()">
                                <option v-for="(value, name) in dims" :key="name" v-bind:value="name">{{ name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <label for="scale">Schaal</label>
                            <select class="form-control" id="scale" v-model="scale" @change="zoomMapToScale()">
                                <option v-for="s in printScales" :key="s" v-bind:value="s">1:{{ s }}</option>
                            </select>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-sm-12">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="legend" name="legend"
                                    v-model="legend">
                                <label class="form-check-label" for="legend">Legenda</label>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="row">
                <div class="col-sm-12">
                    <label for="rotation">Rotatie</label>
                    <slider [(ngModel)]="rotation" name="rotation" @change="this.$store.getters.getMap.render()" [value]="0" [min]="0" [max]="360" [step]="1"></slider> {{rotation}}
                </div>
            </div> -->

                    <div class="button-row">
                        <button class="btn btn-success pull-right" v-bind:disabled="exportButton" type="submit"
                            color="primary">Printen
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<style>
div.button-row {
    margin: 1em;
}

.print-form {
    overflow: none;
    width: 95%;
}

.map-root .ol-scale-bar-center {
    left: 50% !important;
    transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    bottom: 1rem;
}
</style>

<script>
import { unByKey } from 'ol/Observable';

import { getPointResolution } from 'ol/proj';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import { Circle, Text, Fill, Stroke, Style } from 'ol/style';

export default {
    name: 'PrintComponent',
    components: {},
    props: {
        organisation: Object,
        featureLayer: Object
    },
    data() {
        return {
            layout: [],
            layouts: [],
            title: '',
            printScales: [1000, 2000, 2500, 5000, 10000, 14000, 15000, 20000, 25000, 28000, 50000, 100000, 250000, 500000],
            printPaperSize: 'A4',
            dims: {
                //  A0: [831, 831],
                //  A1: [584, 584],
                //  A2: [410, 410],
                A3: [287, 317],
                A4: [200, 220],
                A5: [138, 148],
            },
            rotation: 0,
            scale: 5000,
            prevScale: null,
            exportButton: false,
            extentHalfWidth: Number,
            extentHalfHeight: Number,
            PrintStyleTypes: {},
            printConfig: {},
            mapLayer: {},
            legend: false
        }
    },
    mounted() {
        this.printConfig = this.organisation.map_config.tools.print.config;
        this.initPrintMask();
        this.zoomMapToScale();
    },
    beforeDestroy() {
        unByKey(this.printMaskPostrender)
        this.$store.getters.getMap.render();
    },
    methods: {
        initPrintMask() {
            this.$store.getters.getMapLayer = new ImageLayer({
                source: new Static({
                    url: '/img/logos/freijaman.png',
                    imageExtent: this.$store.getters.getMap.getView().calculateExtent(this.$store.getters.getMap.getSize()),
                }),
                map: this.$store.getters.getMap,
                opacity: 0
            });

            this.printMaskPostrender = this.$store.getters.getMapLayer.on('postrender', (evt) => {
                this.context = evt.context;
                const frameState = evt.frameState;
                const resolution = frameState.viewState.resolution;

                const viewportWidth = frameState.size[0] * frameState.pixelRatio;
                const viewportHeight = frameState.size[1] * frameState.pixelRatio;

                const center = [viewportWidth / 2, viewportHeight / 2];

                const height = this.dims[this.printPaperSize][1];
                const width = this.dims[this.printPaperSize][0];
                const ppi = 72;
                const ipm = 39.37;

                this.extentHalfWidth =
                    (((width / ppi) / ipm) * this.scale / resolution) / 2;

                this.extentHalfHeight =
                    (((height / ppi) / ipm) * this.scale / resolution) / 2;
                this.context.fillStyle = 'rgba(0, 5, 25, 0.5)';

                // Draw a mask on the whole map.
                this.context.beginPath();
                this.context.moveTo(0, 0);
                this.context.lineTo(viewportWidth, 0);
                this.context.lineTo(viewportWidth, viewportHeight);
                this.context.lineTo(0, viewportHeight);
                this.context.lineTo(0, 0);
                this.context.closePath();

                // Draw the print zone
                if (!this.rotation) {
                    this.drawPrintZone_(this.context, center, this.extentHalfWidth, this.extentHalfHeight);
                } else {
                    const rotation = this.rotation * (Math.PI / 180);
                    this.drawPrintZoneWithRotation_(this.context, center, this.extentHalfWidth, this.extentHalfHeight, rotation);
                }

                // Fill the mask
                this.context.fill();
            });

            this.$store.getters.getMap.render()
        },
        drawPrintZone_(context, center, extentHalfWidth, extentHalfHeight) {
            const minx = center[0] - extentHalfWidth;
            const miny = center[1] - extentHalfHeight;
            const maxx = center[0] + extentHalfWidth;
            const maxy = center[1] + extentHalfHeight;

            context.moveTo(minx, miny);
            context.lineTo(minx, maxy);
            context.lineTo(maxx, maxy);
            context.lineTo(maxx, miny);
            context.lineTo(minx, miny);
            context.closePath();
        },
        drawPrintZoneWithRotation_(context, center, extentHalfWidth, extentHalfHeight, rotation) {
            // diagonal = distance p1 to center.
            const diagonal = Math.sqrt(Math.pow(extentHalfWidth, 2) +
                Math.pow(extentHalfHeight, 2));
            // gamma = angle between horizontal and diagonal (with rotation).
            const gamma = Math.atan(extentHalfHeight / extentHalfWidth) - rotation;
            // omega = angle between diagonal and vertical (with rotation).
            const omega = Math.atan(extentHalfWidth / extentHalfHeight) - rotation;
            // Calculation of each corner.
            const x1 = center[0] - Math.cos(gamma) * diagonal;
            const y1 = center[1] + Math.sin(gamma) * diagonal;
            const x2 = center[0] + Math.sin(omega) * diagonal;
            const y2 = center[1] + Math.cos(omega) * diagonal;
            const x3 = center[0] + Math.cos(gamma) * diagonal;
            const y3 = center[1] - Math.sin(gamma) * diagonal;
            const x4 = center[0] - Math.sin(omega) * diagonal;
            const y4 = center[1] - Math.cos(omega) * diagonal;

            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.lineTo(x3, y3);
            context.lineTo(x4, y4);
            context.lineTo(x1, y1);
            context.closePath();
        },
        zoomMapToScale() {
            this.$store.getters.getMap.getView().animate({ resolution: this.getResolutionFromScale(this.scale) });
        },
        getResolutionFromScale(scale) {
            var dpi = 25.4 / 0.28;
            var inchesPerMeter = 39.37;

            return scale / (1 * inchesPerMeter * dpi);
        },
        print() {
            this.$store.commit('setLoading', true);

            this.context.restore();

            this.exportButton = true;
            const exportOptions = {
                useCORS: true,
                ignoreElements: (element) => {
                    if (
                        element.nodeName == "button" ||
                        element.nodeName == "svg" ||
                        element.nodeName == "path"
                    ) {
                        return false;
                    }
                    const className = element.className || undefined;
                    if (className && className.indexOf) {
                        return !(
                            className.indexOf('ol-control') === -1 ||
                            className.indexOf('ol-scale') > -1 ||
                            (className.indexOf('ol-attribution') > -1 &&
                                className.indexOf('ol-uncollapsible'))
                        );
                    }
                },
            };

            const width = Math.round((this.dims[this.printPaperSize][0] * 200) / 25.4);
            const height = Math.round((this.dims[this.printPaperSize][1] * 200) / 25.4);
            const viewResolution = this.$store.getters.getMap.getView().getResolution();

            const scaleResolution =
                (this.scale / 1000) /
                getPointResolution(
                    this.$store.getters.getMap.getView().getProjection(),
                    200 / 25.4,
                    this.$store.getters.getMap.getView().getCenter()
                ) / 3;

            if (this.featureLayer) {
                this.featureLayer.setStyle(feature => {
                    return new Style({
                        stroke: new Stroke({
                            color: "#af41f4",
                            width: 2
                        }),
                        text: new Text({
                            text: feature.get('text'),
                            textAlign: 'center',
                            offsetY: -20,
                            scale: 3,
                            overflow: true,
                            stroke: new Stroke({
                                color: '#ffffff',
                                width: 2
                            })
                        }),
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({
                                color: '#af41f4'
                            })
                        })
                    });
                });
            }

            this.$store.getters.getMap.once('rendercomplete', (e) => {
                // Make sure the created image has the good dimensions
                exportOptions.width = width;
                exportOptions.height = height;
                html2canvas(this.$store.getters.getMap.getViewport(), exportOptions).then((canvas) => {

                    this.createPDF(canvas.toDataURL('image/jpeg'), 'portrait');

                    document.getElementsByClassName('ol-scale-bar')[0].classList.toggle('ol-scale-bar-center');

                    // Reset original map size
                    this.$store.getters.scaleline.setDpi();
                    this.$store.getters.getMap.getTargetElement().style.width = '';
                    this.$store.getters.getMap.getTargetElement().style.height = '';
                    this.$store.getters.getMap.updateSize();
                    this.$store.getters.getMap.getView().setResolution(viewResolution);
                    this.exportButton = false;
                    document.body.style.cursor = 'auto';

                    if (this.featureLayer) {
                        this.featureLayer.setStyle(feature => {
                            return new Style({
                                stroke: new Stroke({
                                    color: "#af41f4",
                                    width: 2
                                }),
                                text: new Text({
                                    text: feature.get('text'),
                                    textAlign: 'center',
                                    offsetY: -20,
                                    scale: 1.5,
                                    overflow: true,
                                    stroke: new Stroke({
                                        color: '#ffffff',
                                        width: 2
                                    })
                                }),
                                image: new Circle({
                                    radius: 7,
                                    fill: new Fill({
                                        color: '#af41f4'
                                    })
                                })
                            });
                        });
                    }

                    if (!this.rotation) {
                        this.drawPrintZone_(this.context, center, this.extentHalfWidth, this.extentHalfHeight);
                    } else {
                        const rotation = this.rotation * (Math.PI / 180);
                        this.drawPrintZoneWithRotation_(this.context, center, this.extentHalfWidth, this.extentHalfHeight, rotation);
                    }
                });
            });
            document.getElementsByClassName('ol-scale-bar')[0].classList.toggle('ol-scale-bar-center');
            this.$store.getters.scaleline.setDpi(200);
            this.$store.getters.getMap.getTargetElement().style.width = width + 'px';
            this.$store.getters.getMap.getTargetElement().style.height = height + 'px';
            this.$store.getters.getMap.updateSize();
            this.$store.getters.getMap.getView().setResolution(scaleResolution);
        },

        createPDF(image, layout) {
            const pdf = new jsPDF(layout, undefined, this.printPaperSize);

            console.log('width: ' + pdf.internal.pageSize.getWidth(), 'heigt: ' + pdf.internal.pageSize.getHeight())
            const imgProps = pdf.getImageProperties(image);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(
                image,
                'JPEG',
                5,
                5,
                this.dims[this.printPaperSize][0],
                this.dims[this.printPaperSize][1]
            );

            pdf.addImage(
                window.location.origin + '/img/logos/freijaman.png',
                'JPEG',
                7, 7, 29, 25
            );

            pdf.addImage(
                window.location.origin + '/assets/NorthArrow.png',
                'PNG',
                pdfWidth - 19, 7, 10, 15,
                undefined, undefined,
                this.rotation
            );

            this.customElements(pdf);

            pdf.setFontSize(10)

            // Print info
            pdf.setFont(undefined, 'bold')
            pdf.text(this.title ? this.title : 'Print BOOM', 9, this.dims[this.printPaperSize][1] + 13);
            pdf.setFont(undefined, 'normal');
            const date = `Datum: ${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
            pdf.text(date, 9, this.dims[this.printPaperSize][1] + 18);
            pdf.text('Formaat: ' + this.printPaperSize, 9, this.dims[this.printPaperSize][1] + 23);
            pdf.text('Schaal: 1:' + this.scale, 9, this.dims[this.printPaperSize][1] + 28);

            // Client info
            pdf.text('Voor meer informatie kunt u contact met ons opnemen.', 9, this.dims[this.printPaperSize][1] + 43);
            pdf.text('Website: ' + this.organisation.website_url, 9, this.dims[this.printPaperSize][1] + 48);
            pdf.text('Telefoon: ' + this.organisation.telephone_number, 9, this.dims[this.printPaperSize][1] + 53);
            pdf.text('Email: ' + this.organisation.email, 9, this.dims[this.printPaperSize][1] + 58);
            pdf.text('Adres: ' + this.organisation.address, 9, this.dims[this.printPaperSize][1] + 63);

            pdf.setFontSize(7);
            pdf.text("Aan dit kaartproduct kunnen geen rechten worden ontleend.", 9, pdf.internal.pageSize.height - 8);
            pdf.save('map.pdf');

            this.$store.commit('setLoading', false);
        },

        // Because there is no scaling support in pdf's we create a special layout for diffrent images
        customElements(pdf) {
            console.log(this.organisation.id)
            let legendX;
            let legendY;
            let legendHeight;
            let legendWidth;
            let logoHeight = 25;
            let logoWidth = 25;
            switch (this.organisation.id) {
                // NHW
                // img/logos/NHW_colour.jpg
                case 2:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 160;
                    legendWidth = 40;
                    legendHeight = 155;
                    logoWidth = 30;
                    break;
                // Maasheggen
                // img/logos/boom_sbb.png
                case 4:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 160;
                    legendWidth = 40;
                    legendHeight = 155;
                    logoWidth = 20;
                    break;
                // VALA
                // img/logos/vala.png
                case 5:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 105;
                    legendWidth = 40;
                    legendHeight = 100;
                    logoWidth = 50;
                    break;
                // NFW
                // img/logos/NFW.jpg
                case 6:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 95;
                    legendWidth = 40;
                    legendHeight = 90;
                    logoWidth = 25;
                    break;
                // Landgoed Ampsen
                // img/logos/logo_ampsen.png
                case 7:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 160;
                    legendWidth = 40;
                    legendHeight = 155;
                    logoWidth = 50;
                    break;
                // Drenthe
                // img/logos/drenthe.png
                case 8:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 120;
                    legendWidth = 40;
                    legendHeight = 115;
                    logoWidth = 30;
                    break;
                // Natuurrijk limburg
                // img/logos/natuurrijk_limburg.png
                case 9:
                    legendX = this.dims[this.printPaperSize][0] - 45;
                    legendY = pdf.internal.pageSize.getHeight() - 160;
                    legendWidth = 50;
                    legendHeight = 155;
                    break;
                // Van Groene waarde
                // img/logos/vangroenewaarde.png
                // case 10:
                //     legendX = this.dims[this.printPaperSize][0] - 35;
                //     legendY = pdf.internal.pageSize.getHeight() - 160;
                //     legendWidth = 40;
                //     legendHeight = 155;
                // break;
                // SBB Achterhoek
                // img/logos/staatsbosbeheer.png
                case 11:
                    legendX = this.dims[this.printPaperSize][0] - 35;
                    legendY = pdf.internal.pageSize.getHeight() - 160;
                    legendWidth = 40;
                    legendHeight = 155;
                    logoWidth = 50;
                    break;
                // Groningen west
                // img/logos/groningen-west.png
                case 13:
                    legendX = this.dims[this.printPaperSize][0] - 45;
                    legendY = this.dims[this.printPaperSize][0] + 5;
                    legendWidth = 50;
                    legendHeight = 55;
                    logoWidth = 50;
                    break;
                // Centrale as
                // img/logos/centrale_as.jpg
                case 14:
                    legendX = this.dims[this.printPaperSize][0] - 45;
                    legendY = pdf.internal.pageSize.getHeight() - 120;
                    legendWidth = 50;
                    legendHeight = 115;
                    logoWidth = 50;
                    break;
                default:
                    legendX = this.dims[this.printPaperSize][0] - 40;
                    legendY = pdf.internal.pageSize.getHeight() - 100;
            }

            if (this.legend) {
                pdf.addImage(
                    `${window.location.origin}/img/legend/${this.printConfig.legend}`,
                    'PNG',
                    legendX, legendY, legendWidth, legendHeight
                );
            }

            console.log(window.location.origin + '/' + this.organisation.logo)
            pdf.addImage(
                window.location.origin + '/' + this.organisation.logo,
                'JPEG',
                50, this.dims[this.printPaperSize][1] + 8, logoWidth, logoHeight
            );
        }
    }
}
</script>
