$(function() {
    // Datatables
    var $table = $('#contracts-table');

    if ($table.length) {

        var dataTable = $table.DataTable({
            ajax: contractsTableUrl,
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            select: true,
            scrollY: '50vh',
            scrollX: '50vh',
            serverSide: true,
            ordering: true,
            order: [
                [0, 'desc']
            ],
            columnDefs: [{
                targets: [2, 3],
                orderable: false
            }],
            columns: [
                { data: 'created_at', name: 'created_at' ,
                "render": function (data) {
                    var date = new Date(data);
                    var month = date.getMonth() + 1;
                    return  date.getDate() + "/" + (month.toString().length > 1 ? month : "0" + month) + "/"  + date.getFullYear();}},
                { data: 'owner.owner', name: 'owner.owner', defaultContent: '' },
                {
                    data: 'id',
                    name: 'download',
                    defaultContent: '',
                    render: function(data, type, row) {
                        return '<a href="/contracts/download/' + row.id + '">Download</a>'
                    }
                },
                {
                    data: 'id',
                    name: 'delete',
                    defaultContent: '',
                    render: function(data, type, row) {
                        return '<a onclick=\'return confirm("Druk op OK om te verwijderen");\' href="/contracts/delete/' + row.id + '">Verwijder</a>'
                    }
                }
            ],
            fnInfoCallback: function(oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                $('#counter').text(iTotal);
                if (typeof window.tableHeight == 'undefined') {
                    window.tableHeight = $('div.dataTables_scrollBody').height();
                    console.log(window.tableHeight);
                }
            }
        });
    }
});