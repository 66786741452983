(function ($) {
    
    // Fix to allow tables being used for previews
    Dropzone.createElement = function(string) {
        var el = $(string);
        return el[0];
    };

    Dropzone.options.briefPdf = {

        dictDefaultMessage: 'Sleep een PDF bestand met werkbeschrijving hierheen of klik om een werkbeschrijving te uploaden.',

        paramName: 'pdf',
        acceptedFiles: '.pdf',
        addRemoveLinks: false,
        dictRemoveFile: '',
        dictCancelUploadConfirmation: '',
        dictCancelUpload: '',
        
        previewsContainer: '.dropzone-previews',
        clickable: elementBriefCanUpload ? '#brief-pdf-upload' : null,
        previewTemplate: $('#dz-preview-template tbody').html(),

        init: function () {

            thisDropzone = this;

            thisDropzone.styleRemoveLink = function (link) {
                if (elementBriefCanDelete) {
                    $(link).addClass('btn btn-xs')
                        .append($('<span class="glyphicon glyphicon-remove-circle" style="cursor: pointer;"></span>'));
                }
            };
            
            thisDropzone.stylePreviewElement = function (element, item) {
                $(element)
                    .addClass('dz-complete')
                    .addClass('element-brief-pdf')
                    .attr('rel-id', item.id);
            };

            thisDropzone.on("removedfile", function (file) {
                thisDropzone.files = $(document).find('.element-brief-pdf');
                var $form = $('#brief-pdf');
                $.post(thisDropzone.element.action + '/' + $(file.previewElement).attr('rel-id'), {
                    "_token": $form.find('input[name=_token]').val(),
                    "_method": "DELETE"
                });
            });

            thisDropzone.on("success", function (file, briefPdf) {
                briefPdf = $.parseJSON(briefPdf)
                $(file.previewElement).addClass('element-brief-pdf').attr('rel-id', parseInt(briefPdf.id));
                var pdf_url = homeUrl + '/uploads/files/pdf/brief/el_type_' + briefPdf.organisation_id + '_' + briefPdf.element_type_id + '/' + briefPdf.file_name;
                var $download_link = $('<a></a>')
                    .attr({
                        href: pdf_url,
                        target: '_blank'
                    })
                    .prop('download', briefPdf.name)
                    .addClass('brief-pdf-download-link');
                $(file.previewTemplate).find('.dz-filename span').wrap($download_link);
                
                if (elementBriefCanDelete) {
                    $(file.previewTemplate).find('.dz-remove').show();
                }
            });

            thisDropzone.on("addedfile", function (file) {
                thisDropzone.emit("thumbnail", file, homeUrl + '/img/pdficon.png');
            });

            thisDropzone.on("complete", function (file) {
                thisDropzone.styleRemoveLink(file._removeLink);
            });

            $.get(thisDropzone.element.action, function (data) {
                $.each(JSON.parse(data), function (key, value) {
                    var mockFile = {name: value.name, size: value.size};
                    thisDropzone.options.addedfile.call(thisDropzone, mockFile);
                    thisDropzone.options.thumbnail.call(thisDropzone, mockFile, homeUrl + '/img/pdficon.png');
                    thisDropzone.styleRemoveLink(mockFile._removeLink);
                    thisDropzone.stylePreviewElement(mockFile.previewElement, value);
                    var pdf_url = homeUrl + '/uploads/files/pdf/brief/el_type_' + value.organisation_id + '_' + value.element_type_id + '/' + value.file_name;
                    var $download_link = $('<a></a>')
                        .attr({
                            href: pdf_url,
                            target: '_blank'
                        })
                        .prop('download', value.name)
                        .addClass('brief-pdf-download-link');
                    $(mockFile.previewElement).find('.dz-filename span').wrap($download_link);
                    
                    if (elementBriefCanDelete) {
                        $(mockFile.previewElement).find('.dz-remove').show();
                    }
                });
            });
        }
    };


})(jQuery);
