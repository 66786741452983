<template>
<div>
	<h2>Contracten</h2>

	<div class="row">
		<div class="col-md-12">
			<h4>Geselecteerde elementen</h4>

			<div id="contract-map" class="contract-map"></div>
			<a id="image-download" download="map.png"></a>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<table class="table">
				<thead>
					<tr>
						<th>LSN</th>
						<th>Element type</th>
						<th>Lengte</th>
						<th>Oppervlakte</th>
						<th>Vorm</th>
						<th>Streefbeeld</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="element in contract.elements"  :key="element.id">
						<td>{{ element.lsn }}</td>
						<td>{{ element.elementtype ? element.elementtype.element_type_nl : '' }}</td>
						<td>{{ element.length }} {{ element.length ? 'm' : '' }}</td>
						<td>{{ element.area }} {{ element.area ? 'm2' : '' }}</td>
						<td>{{ element.shape ? element.shape.shape_nl : '' }}</td>
						<td>{{ element.objective ? element.objective.objective_nl : '' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<h4>Onderhoudsmaatregelen</h4>
			<table class="table">
				<thead>
					<tr>
						<th>LSN</th>
						<th>Maatregel</th>
						<th>Rekenwaarde</th>
						<th>Eenheid</th>
						<th>Periode van</th>
						<th>Periode tot</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="element in contract.elements">
						<tr v-for="m in element.maintenance" :key="m.id">
							<template v-if="m.status.maintenance_status === 'planned' || m.status.maintenance_status === 'postponed'">
								<td>{{ element.lsn }}</td>
								<td style="width:55%">{{ m.maintenancetype ? m.maintenancetype.maintenance_type_nl : '' }}
									<template v-for="note in m.notes">
										Notitie: {{ note.note }}
									</template>
								</td>
								<td>{{ m.calculation_value ? m.calculation_value : '' }}</td>
								<td>{{ m.unit ? m.unit.unit : ""}}</td>
								<td>{{ new Date(m.period_from).toDateString() }}</td>
								<td>{{ new Date(m.period_to).toDateString() }}</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="form-group pull-right">
				<a class="btn btn-default" href="/contracts/new">Terug</a>
				<button class="btn btn-primary" type="button" href="" v-on:click="save()">Bekijk en teken overeenkomst</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Map from 'ol/Map'
import View from 'ol/View'
import VectorLayer from 'ol/layer/Vector'
import ImageLayer from 'ol/layer/Image'
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON';
import Draw from 'ol/interaction/Draw';
import { Snap, Modify } from 'ol/interaction';
import { shiftKeyOnly } from 'ol/events/condition';
import {
    register
} from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';
import {getPointResolution} from 'ol/proj';

import { Circle, Text, Fill, Stroke, Style, Icon } from 'ol/style';
import html2canvas from 'html2canvas';

export default {
	name: 'ContractStepThree',
    components: {
		Multiselect: window.VueMultiselect.default
	},
	props: {
        contract: Object,
        organisation: Object
    },
	data() {
		return {
			vModelLike: "",
			options: [],
			value: null,
			owner: undefined,
			endDate: new Date(2021, 11, 31),
			featureLayer: Object,
			map: Object
		}
	},
	mounted() {
		this.createMap();
	},
	methods: {
		createMap() {
			this.featureLayer = new VectorLayer({
				zIndex: 3,
				source: new VectorSource()
			});

            this.featureLayer.setStyle(features => {
                    return new Style({
                        stroke: new Stroke({
                            color: "#4caf50",
                            width: 2
                        }),
                        text: new Text({
                            text: features.get('lsn'),
                            textAlign: 'center',
                            offsetY: -20,
                            scale: 1.4,
                            stroke: new Stroke({
                                color: '#ffffff',
                                width: 3
                            })
                        }),
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({
                                color: '#4caf50'
                            })
                        })
                    });
            });

			// Default extent if there is no feature extent
        	const extent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];

			// Dutch projection
			const projection = new Projection({
				code: 'EPSG:28992',
				units: 'm',
				extent
			});
			proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs');
			proj4.defs('urn:x-ogc:def:crs:EPSG:28992', proj4.defs('EPSG:28992'));
			proj4.defs('http://www.opengis.net/gml/srs/epsg.xml#28992', proj4.defs('EPSG:28992'));
			register(proj4);

			this.map = new Map({
				layers: [
					new ImageLayer({
						zIndex: 2,
						source: new ImageWMS({
							url: 'https://kaarten.boomapp.nl/?map=/etc/mapserver/nfw_centrale_as.map',
							params: {
								LAYERS: [
									'luchtfoto_actueel'
								],
								FORMAT: 'image/png',
								TRANSPARENT: true,
								VERSION: '1.1.1',
								TILED: false
							},
						})
                	}),
					this.featureLayer
				],
				target: 'contract-map',
				view: new View({
					projection,
					center: [197800, 454050],
					zoom: 5
				}),
			});

			this.contract.elements.forEach(el => {
				const feature = new GeoJSON().readFeature(el.geometry);
				feature.set('lsn', el.lsn);
				this.featureLayer.getSource().addFeature(feature);
			});

			// Zoom to element
			if (this.featureLayer.getSource().getExtent()) {
				this.map.getView().fit(this.featureLayer.getSource().getExtent(), {minResolution: 1});
			}
		},
		save() {
			var formData = new FormData();

			const exportOptions = {
				useCORS: true,
				ignoreElements: function (element) {
					const className = element.className || '';
					return !(
					className.indexOf('ol-control') === -1 ||
					className.indexOf('ol-scale') > -1 ||
					(className.indexOf('ol-attribution') > -1 &&
						className.indexOf('ol-uncollapsible'))
					);
				},
			};

			const dims = {
				a0: [1189, 841],
				a1: [841, 594],
				a2: [594, 420],
				a3: [420, 297],
				a4: [297, 210],
				a5: [210, 148],
			};

			const format = 'a4';
			const resolution = 150;
			const scale = 4;
			const dim = dims[format];
			const width = Math.round((dim[0] * resolution) / 25.4);
			const height = Math.round((dim[1] * resolution) / 25.4);
			const viewResolution = this.map.getView().getResolution();
			const scaleResolution = scale /
				getPointResolution(
					this.map.getView().getProjection(),
					resolution / 25.4,
					this.map.getView().getCenter()
				);


			this.map.once('rendercomplete', (e) => {
				exportOptions.width = width;
				exportOptions.height = height;
				html2canvas(this.map.getViewport(), exportOptions).then((canvas) => {
				
					formData.append("id",  this.contract.id );
					formData.append("map", canvas.toDataURL('image/jpeg')); 

					axios({
						method: 'post',
						url: contractMapUrl,
						data: formData,
            		}).then((res) => {
						window.location.href = res.data.route;
					})

					// Reset original map size
					// scaleLine.setDpi();
					this.map.getTargetElement().style.width = '';
					this.map.getTargetElement().style.height = '';
					this.map.updateSize();
					this.map.getView().setResolution(viewResolution);
					document.body.style.cursor = 'auto';
				});
			});
			
			this.map.getTargetElement().style.width = width + 'px';
			this.map.getTargetElement().style.height = height + 'px';
			this.map.updateSize();
			this.map.getView().setResolution(scaleResolution);
			this.map.getView().fit(this.featureLayer.getSource().getExtent(), {padding: [500,500,500,500]});
		}
	}
}

</script>

<style>
.card {
    padding-top: 10px;
    padding-bottom: 10px;
}

.createTools {
    position: fixed;
    right: 120px;
    top: 120px;
    z-index: 10;
}

div.button-row {
    margin: 1em;
}

div.contract-map {
	height: 300px;
}
</style>
