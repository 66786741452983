<template>
<div class="root">
    <div class="loading_screen" v-if="$store.getters.loading"></div>
    <div ref="map-root" id="map-root" class="map-root"></div>
    <div class="close-sidebar">
        <button id="legenda-click" class="legend-click icon-up btn"></button>
    </div>
    <div id="legendaNav" class="legenda-group">
        <div class="btn-group btn-legend">
                <button
                    v-for="tab in tabs" :key="tab"
                    v-on:click="$store.commit('setActiveTab', tab)"
                    :class="['btn btn-default', { active: $store.getters.activeTab === tab }]">
                    <!-- <img src="{{ tab }}svg"> -->
                    <img :src="'/img/icons/'+tab+'.svg'"
                    width="18px"
                    height="18px"
                    >
                        <!-- <img src="'/img/icons/'+{{ tab }}+'.svg'"> -->
                        <!-- <img :src="'/img/icons/'+tab+'.svg'"> -->
                        <!-- {{ tab }} -->

                </button>
            </div>
        <div class='legend'>
            <template v-if="mapLoaded">
                <!-- <img src="/img/icons/legenda.svg" alt=""> -->
                <div v-if="$store.getters.activeTab === 'Legenda'" class="legenda">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"/></svg>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"/></svg>
                    </div>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>
                    </div> -->
                    <legend-component :layers="layers" :maps="maps"></legend-component>
                </div>
                <div v-else-if="$store.getters.activeTab === 'Printen'">
                    <print-component :organisation="organisation" :featureLayer="featureLayer"></print-component>
                </div>
                <div v-else-if="$store.getters.activeTab === 'Info'" class="legenda">
                    <div v-if="featureLayer && featureLayer.getSource().getFeatures() != undefined">
                        <detail-component
                            :features="featureLayer.getSource().getFeatures()"
                            :organisation="organisation"
                            :user="user"
                            :elements_data="elements_data"
                        ></detail-component>
                    </div>
                    <info-component
                        :organisation="organisation"
                        :user="user"
                        :elements_data="elements_data"
                    ></info-component>
                </div>
                <div v-if="$store.getters.canCreate">
                    <create-component
                        :organisation="organisation"
                        :user="user"
                        :elementsData="elements_data"
                    ></create-component>
                </div>
            </template>
        </div>
    </div>
    <tools-component v-if="mapLoaded"
        :organisation="organisation"
    ></tools-component>

    <div v-on:click="changeBackground()" class="backgroundSwitch">
        <img :src="backgroundUrl" alt="">
    </div>
</div>
</template>

<style>
.root {
    height: 100%;
    width: 100%;
}

.loading_screen {
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.363);
}

.map-root {
    width: 100%;
    height: 100%;
    float: right;
}
.ol-layer {
    width: 100%;
    height: 100%;
    float: right;
}

div.legenda {
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-left: 0;
    max-height: 76vh;
}

div.btn-group button.active {
    border-bottom: solid 3px rgba(76, 175, 80, 0.7);
}

.icons {
    width: 18px;
    height: 18px;
}

.btn-group {
    width: auto;
}

.btn-legend {
    overflow: hidden;
    height: 7%;
    border-bottom: 1px solid rgba(0,0,0,.12);
    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.legenda-group {
    position: absolute;
    width: 25%;
    padding: 15px;
    padding-right: 0;
    height: 86vh;
    top: 64px;
    z-index: 10;
    background-color: white;
}

.map-root  .ol-zoom {
    top: .5em;
    left: 25%;
    transition: 0.2s;
}

.map-root .ol-zoom-out {
    top: .5em;
    left: .5em;
}

.map-root .ol-scale-bar {
    left: 28%;
    position: absolute;
    transition: 0.2s;
}

.map-root .ol-scale-bar-out {
    left: 8px;
    position: absolute;
}

div.close-sidebar {
	position: fixed;
    left: 25%;
    border-left: none;
    margin-top: 55px;
    z-index: 1000;
    transition: 0.2s;
}

div.open-sidebar {
	position: fixed;
    margin-top: 55px;
    left: 0;
    z-index: 1000;
    transition: 0.2s;
}

.icon-up {
    position: relative;
    padding-left: 1em;
}

.icon-up::before {
    content: '<';
    display: block;
    font-size: 26px;
    transition: transform .2s ease;
    transform: rotate(0);
}

.rotate.icon-up::before {
    transform: rotate(180deg);
}

div.close-sidebar button {
	font-size: 16px;
	width: 23px;
	height: 48px;
	background-color: #fff;
	border-radius: 0px;
	padding: 0;
	border-left: 1px solid #D4D4D4;
	box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.3)
}

button {
    outline: none !important;
}

.backgroundSwitch {
    position: absolute;
    bottom: 100px;
    right: 45px;
    height: 55px;
    width: 55px;
    background-color: white;
    border: 2px solid #fff;
	box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
}
.backgroundSwitch img {
    width: 51px;
    height: 51px;
}


</style>

<script>
import View from 'ol/View';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import { boundingExtent } from 'ol/extent';
import {ScaleLine, defaults as defaultControls} from 'ol/control';
import * as OlSource from 'ol/source';
import * as OlLayers from 'ol/layer';
import { Circle, Text, Fill, Stroke, Style } from 'ol/style';
import {
    register
} from 'ol/proj/proj4';
import Projection from 'ol/proj/Projection';
import proj4 from 'proj4';

import 'ol/ol.css';

import LegendComponent from './LegendComponent.vue';
import PrintComponent from './PrintComponent.vue';
import InfoComponent from './InfoComponent.vue';
import CreateComponent from './CreateComponent.vue';
import ToolsComponent from './ToolsComponent.vue';

import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        activeTab: 'Legenda',
        map: {},
        interaction: undefined,
        activeTool: undefined,
        infoClick: undefined,
        clickEvent: undefined,
        infoClickState: false,
        newFeature: undefined,
        canCreate: false,
        scaleline: new ScaleLine({bar: true, minWidth: 150}),
        loading: false
    },
    mutations: {
        setMap(state, map) {
            state.map = map;
        },
        newInteraction (state, interaction) {
            state.interaction = interaction
        },
        activeTool (state, evt) {
            state.activeTool = evt
        },
        infoClick (state, interaction) {
            state.infoClick = interaction
        },
        infoClickState (state, evt) {
            state.infoClickState = evt
        },
        setActiveTab(state, tab) {
            state.activeTab = tab;
        },
        clickEvent (state, evt) {
            state.clickEvent = evt
        },
        setNewFeature (state, evt) {
            state.newFeature = evt
        },
        setLoading (state, evt) {
            state.loading = evt
        },
        setCanCreate(state, evt) {
            state.canCreate = evt
        }
    },
    actions: {
        toggleClickOff (state) {
            state.commit('infoClickState', false);
            // This does not disable the click in any way
            // @Todo fix this
            state.getters.getMap.un('singleclick', state.getters.infoClick);
            // state.getters.getMap.removeInteraction(state.getters.infoClick);
            // state.getters.getMap.unset('singleclick');
            // state.commit('infoClick', undefined);
        },
        toggleClickOn (state) {
            state.commit('infoClickState', true);
            state.commit('infoClick',
                state.getters.getMap.on('singleclick', evt => {
                    // Temporary fix to disable the click
                    if (state.getters.infoClickState) {
                        state.commit('setActiveTab', 'Info')
                        state.commit('clickEvent', {
                            "type": "Point",
                            "coordinates": evt.coordinate
                        });
                    }
                })
            );
        }
    },
    getters: {
        activeTab: state => state.activeTab,
        interaction: state => state.interaction,
        getMap: state => state.map,
        clickEvent: state => state.clickEvent,
        infoClick: state => state.infoClick,
        infoClickState: state => state.infoClickState,
        activeTool: state => state.activeTool,
        newFeature: state => state.newFeature,
        scaleline: state => state.scaleline,
        loading: state => state.loading,
        canCreate: state => state.canCreate
    }
})

export default {
    name: 'Map',
    store,
    components: {
        LegendComponent,
        PrintComponent,
        InfoComponent,
        CreateComponent,
        ToolsComponent
    },
    data: () => ({
        map: {},
        layers: [],
        mapLoaded: false,
        tabs: ["Legenda", "Printen","Info"],
        featureLayer: undefined,
        background: [],
        backgroundUrl: window.location.origin + '/img/legend/icons/satellite.png',
    }),
    props: {
        organisation: Object,
        maps: Array,
        features: Object,
        user: Object,
        elements_data: Object
    },
    mounted() {
        let config = this.organisation.map_config;

        const projExtent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];
        // Dutch projection
        const projection = new Projection({
            code: 'EPSG:28992',
            units: 'm',
            extent: projExtent
        });
        proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs');
        proj4.defs('urn:x-ogc:def:crs:EPSG:28992', proj4.defs('EPSG:28992'));
        proj4.defs('http://www.opengis.net/gml/srs/epsg.xml#28992', proj4.defs('EPSG:28992')); // Used by geoserver
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
        register(proj4);

        this.background.push(
            new OlLayers.Tile({
                source: new OlSource.TileWMS({
                    crossOrigin: 'Anonymous',
                    url: 'https://mapproxy.gisarts.nl/service',
                    params: {
                        LAYERS: [
                            'openstreetmap'
                        ],
                        FORMAT: 'image/png',
                        TRANSPARENT: true,
                        VERSION: '1.1.1',
                        TILED: false
                    },
                })
            }),
        )

        this.background.push(
            new OlLayers.Image({
                source: new OlSource.ImageWMS({
                    crossOrigin: 'Anonymous',
                    url: 'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0',
                    params: {
                        LAYERS: [
                            '2021_orthoHR'
                        ],
                        FORMAT: 'image/png',
                        TRANSPARENT: true,
                        VERSION: '1.1.1',
                        TILED: false
                    },
                }),
                visible: false
            }),
        )

        if (this.$store.getters.canCreate) {
            this.tabs.push("Create");
        }

        if (this.features) {
            // a new vector layer is created with the features
            this.featureLayer = new OlLayers.Vector({
                zIndex: 1000,
                source: new OlSource.Vector({
                    features: new GeoJSON().readFeatures(this.features),
                })
            });

            this.featureLayer.setStyle(feature => {
                    return new Style({
                        stroke: new Stroke({
                            color: "#af41f4",
                            width: 2
                        }),
                        text: new Text({
                            text: feature.get('text'),
                            textAlign: 'center',
                            offsetY: -20,
                            scale: 1.5,
                            overflow: true,
                            stroke: new Stroke({
                                color: '#ffffff',
                                width: 2
                            })
                        }),
                        image: new Circle({
                            radius: 7,
                            fill: new Fill({
                                color: '#af41f4'
                            })
                        })
                    });
            });
        }

        this.$store.commit('setMap',
            new Map({
                target: this.$refs['map-root'],
                layers: this.background,
                controls: defaultControls(),
                view: new View({
                    projection,
                    // enableRotation: false,
                    center: [197800, 454050],
                    minZoom: config.minZoom ? config.minZoom : 5,
                    maxZoom: config.maxZoom ? config.maxZoom : 22,
                }),
            })
        );

        this.$store.getters.getMap.addControl(this.$store.getters.scaleline);

        // Turn on the infoclick
        this.$store.dispatch('toggleClickOn');

        // Add the maps to the map and set the new array to the legend
        this.maps.forEach(l => {
            this.addLayer(this.$store.getters.getMap, l.options);
        })

        // Zoom to the extent if available
        if (this.featureLayer) {
            this.$store.getters.getMap.addLayer(this.featureLayer);
            this.$store.getters.getMap.getView().fit(this.featureLayer.getSource().getExtent(),
                {padding: [200,200,200,200], maxZoom: 17});
        } else {
            this.zoomToExtent(this.$store.getters.getMap, config.extent);
        }

        this.layers = this.$store.getters.getMap.getLayers().array_;
        this.mapLoaded = true;

        this.user.roles.forEach(role => {
            const r = JSON.parse(role.permissions);
            if (r.create_element) {
                this.$store.commit('setCanCreate', true)
            }
        });

        if(this.featureLayer != undefined){
            if(this.featureLayer.getSource().getFeatures().length > 0){
                this.$store.commit('setActiveTab', 'Info')
            }
        }
    },
    methods: {
        zoomToExtent(map, extent) {
            if (extent.constructor === Array) {
                extent = boundingExtent(extent);
            } else {
                const geojson = new GeoJSON();
                const feature = geojson.readFeature(extent);
                extent = feature.getGeometry().getExtent();
            }

            map.getView().fit(extent, map.getSize());
        },

        addLayer(map, l) {
            // Modify the maps layers so they can be used on the new layer below
            const layers = [];
            let featureTypes = [];

            // Load layers directly
            l.source.layers.forEach(l => {
                if (typeof l === 'string') {
                    layers.push(l);
                } else if (l.name != undefined) {
                    if (l.visible !== false) {
                        layers.push(l.name);
                    }

                    // Add the layer to the protocol which we can use for feature requests
                    if (l.protocol != undefined) {
                        this._featureService.protocol.push(l.name);
                    }

                    // Add featuretypes
                    if (!map.featureTypes && l.featureType) {
                        featureTypes.push(l.featureType);
                    }
                }
            });

            if (l.source.layer_groups && l.source.layer_groups.length) {
                // Load layers from groups
                l.source.layer_groups.forEach(group => {
                    if (group.layerName) {
                        // Enable all layers in groups used as layers
                        if (group.visible) {
                            layers.push(group.layerName)
                        }
                    } else {
                        group.layers.forEach(l => {
                            if (typeof l === 'string') {
                                layers.push(l);
                            } else if (l.name != undefined) {

                                if (l.visible !== false) {
                                    layers.push(l.name);
                                }

                                // Add the layer to the protocol which we can use for feature requests
                                if (l.protocol != undefined) {
                                    this._featureService.protocol.push(l.name);
                                }

                                // Add featuretypes
                                if (!map.featureTypes && l.featureType) {
                                    featureTypes.push(l.featureType);
                                }
                            }
                        });
                    }
                })
            }



            // Check if there are featureTypes on the map, these are the leading featureTypes over layer featureTypes
            // if (map.featureTypes) {
            //     if (map.featureTypes !== Array) {
            //         featureTypes = map.featureTypes.split(',');
            //     } else {
            //         featureTypes = map.featureTypes;
            //     }
            // }

            // Create layer source
            let layerSource;

            switch (l.type) {
                case 'Image':
                    layerSource = this.createImageSource(l, layers);
                    break;
                case 'Tile':
                    layerSource = this.createTileSource(l, layers);
                    break;
                case 'Vector':
                    layerSource = this.createVectorSource(l, layers);
                    break;
                default:
                    break;
            }

            // Add CQL filter to the params when present in our config
            // @TODO: check this, it should work with our new param loader
            // if(map.source.params.cql_filter){
            //     let params = layerSource.getParams();
            //     params['CQL_FILTER'] = map.source.params.cql_filter;
            //
            //     layerSource.updateParams(params);
            // }

            // If there is a layer with marker enable the marker click
            if (l.source.marker && !this.clickService.zoomToClusterEnabled) {
                this.clickService.zoomToClusterEnabled = true;
            }

            // Add attribution if it's present in the config
            if (l.source.attributions) {
                layerSource.setAttributions(l.source.attributions);
            }

            const newLayer = new OlLayers[l.type]({
                title: l.name,
                source: layerSource,
                visible: l.visible != undefined ? l.visible : true,
                maxResolution: l.maxResolution != undefined ? l.maxResolution : undefined,
                minResolution: l.minResolution != undefined ? l.minResolution : undefined,
                opacity: l.opacity != undefined ? l.opacity : 1,
                featureClick: l.featureClick != undefined ? l.featureClick : undefined,
                // featureTypes,
                collapsed: l.collapsed != undefined ? l.collapsed : true,
                icon: l.icon != undefined ? l.icon : false,
                order: l.order
            });

            map.addLayer(newLayer);
        },

        createImageSource(map, layers) {
            // Set some default source parameters
            let params = {
                // Always set the layers
                LAYERS: layers,
                FORMAT: map.source.params.format ? map.source.params.format : "image/png",
                TRANSPARENT: map.source.params.transparent,
                VERSION: map.source.params.version ? map.source.params.version : "1.1.1",
                TILED: false,
                map: map.source.params.map
            };

            let layerSource;
            switch (map.source.type) {
                case 'ImageWMS':
                    layerSource = new OlSource.ImageWMS({
                        url: map.source.url,
                        params,
                        crossOrigin: 'Anonymous',
                        attributions: map.source.attributions,
                        serverType: map.source.serverType ? map.source.serverType : 'mapserver'
                    });

                    if (map.authorization) {
                        layerSource.setImageLoadFunction(async function (image, url) {
                            const headers = new Headers();
                            headers.append('Authorization', map.authorization);
                            const init = {
                                headers: headers,
                                method: 'GET'
                            }
                            const response = await fetch(url, init)
                            const imageData = await response.blob()
                            const imageElement = image.getImage()
                            imageElement.src = window.URL.createObjectURL(imageData)
                        })
                    }

                    break;
                case 'ImageArcGISRest':
                    layerSource = new OlSource.ImageArcGISRest({
                        url: map.source.url,
                        crossOrigin: 'Anonymous',
                        params
                    });
                    break;
                default:
                    layerSource = new OlSource[map.source.type]({
                        url: map.source.url,
                        params,
                    });
                    break;
            }

            return layerSource;
        },
        changeBackground() {
            this.background.forEach(layer => {
                layer.setVisible(!layer.getVisible());
            });

            const osmBackground = window.location.origin + '/img/legend/icons/map.png';

            if (this.backgroundUrl === osmBackground) {
                this.backgroundUrl = window.location.origin + '/img/legend/icons/satellite.png';
            } else {
                this.backgroundUrl = osmBackground;
            }

        }
    }
}

$(function() {
	$("#legenda-click").click(function () {
		$(".legenda-group").animate({
				width: 'toggle'
			}, "fast");
		$(".icon-up").toggleClass('rotate');
		$(".close-sidebar").toggleClass('open-sidebar');
		$(".ol-scale-bar").toggleClass('ol-scale-bar-out');
		$(".ol-zoom").toggleClass('ol-zoom-out');
    });
});
</script>
