<template>
    <div>
        <template v-if="errors && errors.length">
            <div v-for="(error, index) in errors" :key="index" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </template>

        <div class="form-section">
            <div>
                <template v-if="type === 'district'">
                    <div>
                        <label class="form-label" for="district">District:</label>
                        <input id="district" type="text" class="form-control  border border-primary"
                            placeholder="District naam" required v-model="districtName">
                    </div>
                </template>

                <template v-if="type === 'location'">
                    <div class="col-md-8 d-flex">
                        <label class="form-label" for="location">Locatie naam:</label>
                        <input class="form-control border border-primary" id="location" type="text"
                            placeholder="Locatie naam" required v-model="locationName">
                    </div>
                </template>
            </div>

            <div>
                <button type="button" class="btn btn-primary" @click="submit">Opslaan</button>
                <button v-if="geoJson" type="button" class="btn btn-danger" @click="clearDrawLayer()">Tekenlaag
                    verwijderen</button>
            </div>
        </div>

        <div ref="geom-cru" id="geom-cru">
            <div v-on:click="changeBackground()" class="background-switch">
                <img :src="backgroundUrl" alt="Switch Background">
            </div>
        </div>
    </div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { Projection, fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { ImageWMS, TileWMS, Vector as VectorSource } from 'ol/source';
import { Style, Icon, Stroke, Fill, Text } from 'ol/style';
import { Draw, Modify } from 'ol/interaction';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import Axios from 'axios';
import GeoJSON from 'ol/format/GeoJSON';
import ImageLayer from 'ol/layer/Image';

export default {
    name: "GeomCru",
    props: {
        type: 'district' | 'location',
        location: Object | null,
        district: Object | null,
        districts: Array | null,
        locations: Array | null,
    },
    data() {
        return {
            id: null,
            locationName: null,
            districtName: null,
            errors: [],
            map: null,
            vectorLayer: null,
            featureLayer: null,
            districtLayer: null,
            districtsLayer: null,
            locationsLayer: null,
            drawInteraction: null,
            modifyInteraction: null,
            geoJson: null,
            possibleDistricts: [],
            openStreetMap: new TileLayer({
                source: new TileWMS({
                    crossOrigin: 'Anonymous',
                    url: 'https://mapproxy.gisarts.nl/service',
                    params: {
                        LAYERS: [
                            'openstreetmap'
                        ],
                        FORMAT: 'image/png',
                        TRANSPARENT: true,
                        VERSION: '1.1.1',
                        TILED: false
                    },
                }),
                visible: true,
            }),
            luchtFoto: new ImageLayer({
                source: new ImageWMS({
                    url: 'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?',
                    params: {
                        'FORMAT': 'image/jpeg',
                        'VERSION': '1.1.1',
                        'TRANSPARENT': false,
                        'LAYERS': '2023_orthoHR',
                    },
                    serverType: 'mapserver',
                    ratio: 1,
                }),
                visible: false,
            }),
            gemeentegrenzenLayer: new ImageLayer({
                source: new ImageWMS({
                    url: 'https://service.pdok.nl/kadaster/bestuurlijkegebieden/wms/v1_0?',
                    params: {
                        'FORMAT': 'image/png',
                        'VERSION': '1.1.1',
                        'TRANSPARENT': true,
                        'LAYERS': 'Gemeentegebied',
                    },
                    serverType: 'mapserver',
                    ratio: 1,
                }),
                visible: true, // Set to true for testing visibility
            }),
            styleFunction: (feature, map, type = null) => {
                const zoom = map.getView().getZoom();
                const fontSize = Math.max(12, zoom * 2);
                let strokeColor = '#007bff';
                let fillColor = 'rgba(200, 200, 255, 0.5)';
                let textFillColor = '#000';
                let textStrokeColor = '#fff';
                let isVisible = true;

                if (type === 'district') {
                    strokeColor = '#28a745';
                    fillColor = 'rgba(40, 167, 69, 0.2)';
                    textFillColor = '#fff';
                    textStrokeColor = '#000';
                    isVisible = zoom < 8;
                } else if (type === 'location') {
                    strokeColor = '#dc3545';
                    fillColor = 'rgba(220, 53, 69, 0.2)';
                    textFillColor = '#fff';
                    textStrokeColor = '#000';
                    isVisible = zoom > 1;
                }

                return new Style({
                    stroke: new Stroke({
                        color: strokeColor,
                        width: 2
                    }),
                    fill: new Fill({
                        color: fillColor
                    }),
                    text: new Text({
                        text: isVisible ? (feature.get('label') || '') : '',
                        font: `${fontSize}px Arial`,
                        fill: new Fill({
                            color: textFillColor
                        }),
                        stroke: new Stroke({
                            color: textStrokeColor,
                            width: 2
                        }),
                        offsetY: -10,
                        overflow: true
                    })
                });
            },
            backgroundUrl: window.location.origin + '/img/legend/icons/satellite.png',
        };
    },
    mounted() {
        if (typeof this.location === 'object') {
            this.id = this.location.id;
            this.locationName = this.location.location;
            this.geoJson = this.location.geom;
        } else if (typeof this.district === 'object') {
            this.id = this.district.id;
            this.districtName = this.district.district;
            this.geoJson = this.district.geom;
        }

        this.setupDistrict();
        this.setupLocations();

        this.initializeMap();
        this.addInteractions();

        if (!this.id) {
            this.setMode('draw');
        } else {
            this.setMode('modify');
        }

        if (this.geoJson) {
            this.zoomToFeature();
        }
    },
    methods: {
        initializeMap() {
            const extent = [
                -191566.3472920849,
                273632.72750931245,
                514311.1697626629,
                676224.4672743777
            ];

            const projection = new Projection({
                code: 'EPSG:28992',
                units: 'm',
                extent: extent
            });

            proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs');

            register(proj4);

            const center = fromLonLat([5.38763888888889, 52.15616055555555], projection);
            this.vectorLayer = new VectorLayer({
                source: new VectorSource(),
                style: new Style({
                    image: new Icon({
                        anchor: [0.5, 46],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: 'https://openlayers.org/en/latest/examples/data/icon.png',
                    }),
                }),
            });

            this.featureLayer = new VectorLayer({
                source: new VectorSource(),
                style: new Style({
                    stroke: new Stroke({
                        color: '#ffcc33',
                        width: 2,
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 0, 0.1)',
                    }),
                }),
            });

            const coordinates = this.geoJson;

            if (coordinates) {
                const feature = new GeoJSON().readFeature(coordinates);
                this.featureLayer.getSource().addFeature(feature);
                this.geoJson = new GeoJSON().writeGeometry(feature.getGeometry());
            }

            this.map = new Map({
                target: this.$refs['geom-cru'],
                layers: [
                    this.openStreetMap,
                    this.luchtFoto,
                    this.vectorLayer,
                    this.featureLayer,
                    this.gemeentegrenzenLayer,
                ],
                view: new View({
                    center: center,
                    zoom: 0,
                    projection: projection,
                    extent: extent,
                }),
            });
        },
        addInteractions() {
            this.drawInteraction = new Draw({
                source: this.vectorLayer.getSource(),
                type: 'Polygon',
            });

            this.modifyInteraction = new Modify({
                source: this.featureLayer.getSource(),
            });

            this.map.addInteraction(this.drawInteraction);
            this.map.addInteraction(this.modifyInteraction);

            this.drawInteraction.on('drawstart', () => {
                this.geoJson = null;
                this.featureLayer.getSource().clear();
            });

            this.drawInteraction.on('drawend', (event) => {
                const feature = event.feature;
                this.featureLayer.getSource().addFeature(feature);
                this.geoJson = new GeoJSON().writeGeometry(feature.getGeometry());

                this.setMode('modify');
            });

            this.modifyInteraction.on('modifyend', (event) => {
                const feature = event.features.item(0);
                this.geoJson = new GeoJSON().writeGeometry(feature.getGeometry());
            });
        },
        setMode(mode) {
            if (mode === 'draw') {
                this.map.removeInteraction(this.modifyInteraction);
                this.map.addInteraction(this.drawInteraction);
            } else if (mode === 'modify') {
                this.map.removeInteraction(this.drawInteraction);
                this.map.addInteraction(this.modifyInteraction);
            }
        },
        clearDrawLayer() {
            this.geoJson = null;
            this.featureLayer.getSource().clear();
            this.setMode('draw');
        },
        submit(e) {
            this.errors = [];

            if (!this.geoJson) {
                this.errors.push('Geen geometrie getekend');
            }

            if (this.type === 'location') {
                if (!this.locationName) {
                    this.errors.push('Locatie naam is verplicht');
                }
            } else if (this.type === 'district') {
                if (!this.districtName) {
                    this.errors.push('District naam is verplicht');
                }
            }

            if (this.errors.length > 0) {
                e.preventDefault();
                return;
            }

            const body = {
                geojson: this.geoJson,
            };


            if (this.type === 'location') {
                body.location = this.locationName;
            }
            else if (this.type === 'district') {
                body.district = this.districtName;
            }

            const startUrl = `/${this.type}s`;
            const url = `${startUrl}/${this.id ? this.id : 'new'}`;

            Axios.post(url, body).then((response) => {
                window.location.href = startUrl;
            }).catch((error) => {
                this.errors.push(error.response.data);
            });
        },
        zoomToFeature() {
            const feature = this.featureLayer.getSource().getFeatures()[0];

            if (feature) {
                const featureExtent = feature.getGeometry().getExtent();

                this.map.getView().fit(featureExtent, {
                    duration: 1000,
                });
            }
        },
        onSelectDistrict() {
            this.errors = [];

            Axios.get(`/districts/${this.districtId}/map`).then((response) => {
                const { district, geom, locations } = response.data;
                this.setDistrictLayer(district, geom);
                this.setLocationsLayer(locations);
            }).catch((error) => {
                this.errors.push('Er is iets fout gegaan met het ophalen van de geometrie van het district');
            });
        },
        setDistrictLayer(districtName, geom) {
            if (!geom) {
                this.errors.push('Geen geometrie gevonden voor dit district');
                console.error('No geometry found for this district');

                return;
            }

            if (this.districtLayer) {
                this.map.removeLayer(this.districtLayer);
            }

            const vectorSource = new VectorSource();

            const feature = new GeoJSON().readFeature(geom);
            feature.set('label', districtName);
            vectorSource.addFeature(feature);

            this.districtLayer = new VectorLayer({
                source: vectorSource
            });

            this.districtLayer.setStyle((feature) => this.styleFunction(feature, this.map, 'district'));

            this.map.addLayer(this.districtLayer);

            this.map.getView().fit(feature.getGeometry().getExtent(), {
                duration: 1000,
            });
        },
        setLocationsLayer(locations) {
            if (this.locationsLayer) {
                this.map.removeLayer(this.locationsLayer);
            }

            if (!locations || locations.length === 0) {
                return;
            }

            const vectorSource = new VectorSource();

            locations.forEach(location => {
                if (location.id === this.id) {
                    return;
                }

                const feature = new GeoJSON().readFeature(location.geom);
                feature.set('label', location.location);
                vectorSource.addFeature(feature);
            });

            this.locationsLayer = new VectorLayer({
                source: vectorSource
            });

            this.locationsLayer.setStyle((feature) => this.styleFunction(feature, this.map, 'location'));
            this.map.addLayer(this.locationsLayer);
        },
        setupDistrict() {
            Axios.get('/districts/map').then((response) => {
                const districts = response.data;

                if (this.districtsLayer) {
                    this.map.removeLayer(this.districtsLayer);
                }

                if (!districts || districts.length === 0) {
                    return;
                }

                const vectorSource = new VectorSource();

                districts.forEach(district => {
                    if (district.id === this.id) {
                        return;
                    }
                    const feature = new GeoJSON().readFeature(district.geom);
                    feature.set('label', district.district);
                    vectorSource.addFeature(feature);
                });

                this.districtsLayer = new VectorLayer({
                    source: vectorSource
                });

                this.districtsLayer.setStyle((feature) => this.styleFunction(feature, this.map, 'district'));
                this.map.addLayer(this.districtsLayer);
            }).catch((error) => {
                this.errors.push('Er is iets fout gegaan met het ophalen van de districten');
                console.error('Error fetching districts', error);
            });
        },
        setupLocations() {
            Axios.get('/locations/map').then((response) => {
                const locations = response.data;

                if (this.locationsLayer) {
                    this.map.removeLayer(this.locationsLayer);
                }

                if (!locations || locations?.length === 0) {
                    return;
                }

                const vectorSource = new VectorSource();

                locations.forEach(location => {
                    if (location.id === this.id) {
                        return;
                    }

                    const feature = new GeoJSON().readFeature(location.geom);
                    feature.set('label', location.location);
                    vectorSource.addFeature(feature);
                });

                this.locationsLayer = new VectorLayer({
                    source: vectorSource
                });

                this.locationsLayer.setStyle((feature) => this.styleFunction(feature, this.map, 'location'));
                this.map.addLayer(this.locationsLayer);
            }).catch((error) => {
                this.errors.push('Er is iets fout gegaan met het ophalen van de locaties');
                console.error('Error fetching locations', error);
            });
        },
        changeBackground() {
            this.openStreetMap.setVisible(!this.openStreetMap.getVisible());
            this.luchtFoto.setVisible(!this.luchtFoto.getVisible());
            const osmBackground = window.location.origin + '/img/legend/icons/map.png';
            if (this.backgroundUrl === osmBackground) {
                this.backgroundUrl = window.location.origin + '/img/legend/icons/satellite.png';
            } else {
                this.backgroundUrl = osmBackground;
            }
        },
    },
}
</script>

<style>
#geom-cru {
    position: relative;
    height: 600px;
    margin: 20px;
}

.background-switch {
    z-index: 1000;
    position: absolute;
    bottom: 0px;
    right: 10px;
    height: 55px;
    width: 55px;
    background-color: white;
    border: 2px solid #fff;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
    cursor: pointer;
}

.background-switch img {
    width: 51px;
    height: 51px;
}
</style>
