<template>
    <div>
	<h4>Legenda</h4>
	<ul v-if="layers.length">
		<!-- Normal layers -->
		<li class="category" v-for="layer in layers" :key="layer.name">
            <div v-if="maps.find(e => e.options.name === layer.get('title')) !== undefined">
                <div class="state" @click="toggleLayerCollapse(layer)">
                    <div v-if="layer.get('collapsed')">
                        <svg class="bi bi-chevron-down" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"/></svg>
                    </div>
                    <div v-if="!layer.get('collapsed')">
                        <svg width="20" height="20" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>

                <div class="checkbox">
                    <div class="form-check">
                        <input class="ml-1" type="checkbox" v-bind:id="layer.get('title')" name="inlineRadioOptions"  v-bind:checked="layer.getVisible()" value="option1" @click="toggleLayer(layer)">
                        <label v-bind:for="layer.get('title')">
                            <img v-if="layer.get('icon')" v-bind:src="layer.get('icon')"/> {{ layer.get("title") }}
                        </label>
                    </div>
                    <div v-if="!layer.get('collapsed')">
                        <ul v-if="!maps.find(e => e.options.name === layer.get('title')).options.hideInLayerSwitcher">
                            <li class="layer" v-for="paramLayer in maps.find(e => e.options.name === layer.get('title')).options.source.layers" :key="paramLayer.name">
                                <div class="form-check">
                                    <input v-if="paramLayer.name" type="checkbox" id="paramLayer.label" name="inlineRadioOptions" value="option1"
                                    v-bind:checked="getParamLayerVisibility(layer, paramLayer)"
                                    @click="updateLayer(layer, paramLayer.name)">
                                    <label for="paramLayer.label">
                                        <img v-bind:src="paramLayer.icon" v-if="paramLayer.icon" /> {{ paramLayer.label }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Group layers -->
                <!-- <ul *ngIf="isGroup(layer)">
                || !maps.find(e => e.name === layer.get('title')).hideInLayerSwitcher
                &&  maps.find(e => e.name === layer.get('title')).source.layers && maps.find(e => e.name === layer.get('title')).source.layers.length > 1
                    <li class="layer" v-for="let lyr of layer.getLayersArray()">
                        <div class="radio" *ngIf="layer.get('combine')">
                            <label>
                                <input type="radio" name="inlineRadioOptions" value="option1" checked="{{ getVisibility(lyr) }}" (click)="toggleLayer(lyr, layer)"> {{ lyr.get("title") }}
                            </label>
                        </div>
                        <div class="checkbox" *ngIf="!layer.get('combine')">
                            <label>
                                <input type="checkbox" name="inlineRadioOptions" value="option1" checked="{{ getVisibility(lyr) }}" (click)="toggleLayer(lyr)"> {{ lyr.get("title") }}
                            </label>
                            <ul *ngIf="maps[lyr.get('title')]">
                                <li class="layer" v-for="let paramLayer of maps[lyr.get('title')].source.layers">
                                    <label>
                                        <input type="checkbox" name="inlineRadioOptions" value="option1" checked="{{ paramLayer.visible ? 'checked' : '' }}" (click)="updateLayer(lyr, paramLayer.name)"><img src="{{ paramLayer.icon }}" /> {{ paramLayer.label }}
                                    </label>
                                    {{ lyr.getSource().getParams().LAYERS[paramLayer.name] }}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul> -->
            </div>
		</li>
	</ul>
</div>
</template>
<style>
div.category {
    float: left;
}
div.state {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
}

ul {
    padding-left: 0px;
}

div.checkbox {
    margin: 2px 0 2px 30px;
}

div.form-check {
    padding-left: 0px;
}
div.form-check label {
    padding-left: 0px;
}

div.form-check input[type="checkbox"] {
    margin-right: 4px;
    margin-left: 0;
}
.metadata{
    float: right;
    height: 24px;
}
li.category {
    width: 100%;
    min-height: 25px;
    display: block;
    list-style-type: none;
}
li.layer {
    margin-left: 20px;
    list-style-type: none;
}
</style>
<script>

export default {
    name: 'legend-component',
    components: {},
    data: () => ({
        value: [],
        options: [],
    }),
    props: {
        maps: Array,
        layers: Array
    },
    mounted() {},
    methods: {
        toggleLayerCollapse(layer) {
            layer.get('collapsed') ? layer.set('collapsed', false) : layer.set('collapsed', true);
        },

        // Toggle the visibility of a layer
        toggleLayer(layer, layerGroup = undefined) {
            // If a group is set this is a radiogroup, set visibility to false for all other layers in this group
            if(layerGroup) {
                this.changeGroupVisibility(layerGroup, false);
            }

            let layerVisible = layer.getVisible();

            // If the layer has paramLayers, uncheck them when the layer is toggled off
            const layerTitle = layer.get('title');
            if(this.maps[layerTitle] &&  this.maps[layerTitle].source.layers && this.maps[layerTitle].source.layers.length > 1) {
                for(let paramLayer of this.maps[layerTitle].source.layers) {
                    // If layerVisible is true, the layer will be set to invisible below, therefor we want to turn off all paramLayers
                    if(layerVisible) {
                        paramLayer.visible = false;
                    } else {
                        paramLayer.visible = true;
                    }

                }
            }

            layer.getVisible() ? layer.setVisible(false) : layer.setVisible(true);
        },

        // Change the opacity of layer with value of slider
        changeLayerOpacity(value, layer) {
            layer.set('opacity', value/100);
        },

        // Toggle the visibility of a layerGroup, used as click handler
        toggleLayerGroup(layerGroup) {
            var visibility;
            layerGroup.getVisible() ? visibility = false : visibility = true;

            layerGroup.setVisible(visibility)

            // Change the visibility for all the layers in the group aswell
            this.changeGroupVisibility(layerGroup, visibility);
        },

        // Change the visibility of a group
        changeGroupVisibility(layerGroup, visible = false) {
            var layersInGroup = layerGroup.getLayersArray();

            for(var i = 0; i < layersInGroup.length; i++) {
                layersInGroup[i].setVisible(visible);

                if(visible) {
                    // After the layer was set to visible, also set all it's children visible:
                    this.checkAllParamLayers(layersInGroup[i]);
                }
            }
        },

        /**
        * Check all the paramLayers of the given layer
        * @param layer
        */
        checkAllParamLayers(layer) {
            var source = layer.getSource();
            var params = source.getParams();

            // Find all paramLayers that are in the config for this layer and add them if they don't exist
            for (let paramLayer of source.params_.LAYERS) {
                const index = params.LAYERS.indexOf(paramLayer);

                if(index === -1) {
                    // Layer deleted previously, add it again
                    params.LAYERS.push(paramLayer);
                }
            }

            // Trigger the source to let it know it's been updated
            source.updateParams(params);
            source.dispatchEvent('change');
        },

        // Update a layer, rebuilding the params
        updateLayer(layer, paramLayer) {
            var source = layer.getSource();
            var params = source.getParams();

            const index = params.LAYERS.indexOf(paramLayer);

            // Check if the layer is visible, if not, make it visible and uncheck the rest
            if(!layer.getVisible()) {
                this.toggleLayerGroup(layer);

                // Because the layer wasn't visible, we want to uncheck everything except for this layer
                params.LAYERS = [paramLayer];
            } else {
                // Check or uncheck the paramLayer normally

                // If there are is only one layer and we're trying to remove it, disable this layer entirely
                if(params.LAYERS.length < 2 && index != -1) {
                    this.toggleLayerGroup(layer);
                    return;
                }

                if(index != -1) {
                    params.LAYERS.splice(index, 1);
                } else {
                    // Layer already deleted, add it again
                    params.LAYERS.push(paramLayer);
                }
            }

            source.updateParams(params);
            source.dispatchEvent('change');
        },

        isGroup(layer) {
            return layer instanceof ol.layer.Group;
        },

        getVisibility(layer) {
            let layerVisible = layer.getVisible();

            return layerVisible ? "checked" : "";
        },

        /**
        * Determine wether a paramLayer is visible (and thus, if it should be checked)
        * @param  {any}    layer      [description]
        * @param  {any}    paramLayer [description]
        * @return {String}            [description]
        */
        getParamLayerVisibility(layer, paramLayer) {
            if(!layer.getVisible()) {
                return "";
            } else if(typeof layer.getSource().getParams === "function") {
                // Check if the paramLayer is in the LAYERS property of the parameters, if so, it's active
                return layer.getSource().getParams().LAYERS.indexOf(paramLayer.name) > -1 ? 'checked' : '';
            }
        },

        /**
        * Know if the layer is collapsed
        * @param {any} layer [description]
        */
        getCollapsed(layer) {

        },

        loadMetadata(url){
            switch (url) {
                case "Openbasiskaart":
                    window.open("http://www.openstreetmap.org/", '_blank');
                    break;
                case	"BGT":
                    window.open("http://www.kadaster.nl/bgt", '_blank');
                    break;
            }
            console.log(url)
        },
    },
}
</script>
