$(function () {

    var $table = $('#maintenance');

    if ($table.length > 0) {

        let columns = [
            {
                orderable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    if (!row.element) return 'verwijderd';
                    if(row.element.organisation_id == 10) {
                        return '<a target="_blank" href="' + vgwUrl + '\\' + row.element.id + '"' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>';
                    } else {
                        return '<a target="_blank" href="' + cookUrl.replace('_id_', row.element.id) + '"' +
                            '<span class="glyphicon glyphicon-globe"></span>' +
                            '</a>';
                    }
                }
            },
            {
                data: 'element.lsn',
                name: 'element.lsn',
                searchable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    if (!row.element) return 'verwijderd';
                    return '<a href="' + elementUrl.replace('__id__', row.element.id) + '">' + data + '</a>'
                }
            },
            {
                data: 'status.maintenance_status',
                orderable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    return '<div class="status ' + data + '" data-status-id="' + row.maintenance_id + '"></div>'
                }
            },
            {
                data: 'notes',
                orderable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    var number = data.length > 0 ? '' : 'hidden';
                    var empty = data.length > 0 ? 'hidden' : '';
                    return '<span class="label label-primary label-sm click-open-notes notes_number ' + number + ' " data-id="' + row.id + '" data-element-id="' + row.element_id + '">' + data.length + '</span>' +
                        '<span class="label label-default label-sm click-open-notes e_add ' + empty + '" data-id="' + row.id + '" data-element-id="' + row.element_id + '">+</span>';
                }
            },
            // {
            //     data: 'id',
            //     orderable: false,
            //     defaultContent: '',
            //     views: ['default', 'sbb', 'vala'],
            // },
            {
                data: 'element.owners',
                orderable: false,
                defaultContent: '',
                render: function(data, type, row) {
                    if (!row.element) return 'verwijderd';
                    
                    let owners = '';
                    for (let i = 0; i < row.element.owners.length; i++) {
                        if (i) {
                            owners += ', ' + row.element.owners[i].owner;
                        } else {
                           owners += row.element.owners[i].owner;
                        }
                    }
                    return owners;
                },
                views: ['sbb']
            },
            {
                data: 'element.subsidy.deeln_naam',
                name: 'owners.owner',
                defaultContent: '',
                render: function(data, type, row) {
                    if (!row.element) return 'verwijderd';

                    let owners = '';
                    for (let i = 0; i < row.element.owners.length; i++) {
                        if (i) {
                            owners += ', ' + row.element.owners[i].owner;
                        } else {
                           owners += row.element.owners[i].owner;
                        }
                    }
                    return owners;
                },
                views: ['default', 'vala']
            },
            {
                data: 'element.location.location',
                orderable: false,
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'element.elementtype.element_type_' + appLocale,
                orderable: false,
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'element.objective.objective_' + appLocale,
                name: 'element.objective.objective_' + appLocale,
                orderable: false,
                defaultContent: '',
                views: ['default', 'sbb', 'vala'],
            },
            {
                data: 'element.shape.shape_' + appLocale,
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'maintenancetype.maintenance_type_' + appLocale,
                orderable: false,
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'period_from',
                name: 'period_from',
                defaultContent: '',
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    var date = new Date(data);
                    return date.toLocaleDateString("nl-NL");
                }
            },
            {
                data: 'period_to',
                name: 'period_to',
                defaultContent: '',
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    var date = new Date(data);
                    return date.toLocaleDateString("nl-NL");
                }
            },
            {
                data: 'cycle.cycle_' + appLocale,
                defaultContent: '',
                orderable: false,
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'phasing.phasing',
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'element.diameter.diameter',
                name: 'element.diameter.diameter',
                defaultContent: '',
                orderable: false,
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'unit.unit',
                orderable: false,
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'calculation_value',
                name: 'calculation_value',
                defaultContent: '',
                views: ['default', 'sbb', 'vala']
            },
            {
                data: 'tool',
                orderable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    return typeof row.tool[0] != 'undefined' ? row.tool[0]['tool_' + appLocale] : '';
                }
            },
            {
                data: 'waste',
                orderable: false,
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    return typeof row.waste[0] != 'undefined' ? row.waste[0]['waste_' + appLocale] : '';
                }
            },
            {
                data: 'processing',
                views: ['default', 'sbb', 'vala'],
                render: function (data, type, row) {
                    return typeof row.processing[0] != 'undefined' ? row.processing[0]['processing_' + appLocale] : '';
                }
            }
        ]

        var dataTable = $table.DataTable({
            ajax: maintenanceTableUrl,
            dom: 't',
            scroller: {
                loadingIndicator: true
            },
            scrollY: '50vh',
            scrollX: '50vh',
            serverSide: true,
            order: [[1, 'asc']],
            columns: columns.filter(c => c.views ? c.views.includes(view) : false),
            fnInfoCallback: function (oSettings, iStart, iEnd, iMax, iTotal, sPre) {
                $('#counter').text(iTotal);
                if(typeof window.tableHeight == 'undefined'){
                    window.tableHeight =   $('div.dataTables_scrollBody').height();
                }
            }
        });

        var $tbody = $table.find('tbody');

        var maintenance = new Vue({
            el: '#app-maintenance',
            components: {
          	    Multiselect: window.VueMultiselect.default,
        	},
            created: function () {
                this.getFilters(true);
                this.getColumns();
            },
            data: () => ({
                filters: [],
                total: 0,
                search: null,
                show_archived: false,
                maintenance: [],
                searching: null,
                timer: 0,
                columns: {
                    // executed: {index: 2, visible: true},
                    // note: {index: 3, visible: true},
                    // owner: {index: 4, visible: true},
                    // location: {index: 5, visible: true},
                    // element: {index: 6, visible: true},
                    // objective: {index: 7, visible: true},
                    // shape: {index: 8, visible: true},
                    // measure: {index: 9, visible: true},
                    // year: {index: 10, visible: true},
                    // period: {index: 11, visible: true},
                    // cycle: {index: 12, visible: true},
                    // phasing: {index: 13, visible: true},
                    // diameter: {index: 14, visible: true},
                    // unit: {index: 15, visible: true},
                    // calculation: {index: 16, visible: true},
                    // tools: {index: 17, visible: true},
                    // waste: {index: 18, visible: true},
                    // processing: {index: 19, visible: true},
                    // flora: {index: 20, visible: true},
                    // fauna: {index: 21, visible: true}
                }
            }),
            methods: {
                searchTable: function (clear = '') {
                    if (clear == 'clear') {
                        this.search = "";
                    }

                    // Cancel a previous search, if searching is set
                    if(this.searching) {
                        this.searching.cancel();
                    }

                    // Now create a new one
                    this.searching = _.debounce((() => {
                        console.log("Searching ", this.search);
                        dataTable.columns(1).search(this.search).draw();
                        this.getFilters(true);
                    }), 500)

                    this.searching();
                },
                // Multiselect onSelect
                onSelect: function (option, id) {
                    option.checked = true;
                    this.changeFilters(option, id);
                },
                selectAll: function(id){
					this.filters[id].isCheckAll = !this.filters[id].isCheckAll;
					if(this.filters[id].isCheckAll){
						for (var key in this.filters[id].options) {
							this.filters[id].options[key].checked = true
						}
					}else{
						for (var key in this.filters[id].options) {
							this.filters[id].options[key].checked = false
						}
					}
					this.postFilters(this.filters, false);
				},
                // Datepicker onSelect
                datepickerOnSelect: function(e, filter, option, id) {
                    filter.options = { date: [filter.operator, e.toLocaleDateString("en-US")] };
                    this.changeFilters(false, 0);
                    // check not neccesary for date filter
                },
                clearDate: function(filter, option, id) {
                    filter.options = {};
                    this.changeFilters(false, 0);
                    // check not neccesary for date filter
                },
                getColumns: function () {
                    this.$http.get(maintenanceTableColumnUrl).then(function (response) {
                        this.columns = Object.assign({}, this.columns, response.data.columns);
                        $.each(this.columns, function (index, column) {
                            dataTable.column(column.index).visible(column.visible);
                        });
                    }, function () {
                        console.log('Get maintenance table columns error!');
                    });
                },
                postColumns: function () {
                    this.$http.post(maintenanceTableColumnUrl, {columns: this.columns}).then(function (response) {
                        if (!response.data.success) {
                            console.log('Post maintenance columns give false!');
                        }
                    }, function () {
                        console.log('Post maintenance columns error!');
                    });
                },
                customizeTable: function (column) {
                    dataTable.column(column.index).visible(column.visible);
                    this.postColumns();
                },
                getFilters: function (updateFilters) {
                    var url = maintenanceGetFiltersUrl;

                    if (this.search) {
                        url = url + '?columns[1][search][value]=' + this.search;
                    }

                    this.$http.get(url).then(response => {

                        if(this.show_archived != response.data.show_archived){
                            this.show_archived = response.data.show_archived;
                        }
                        dataTable.ajax.reload();
                        if (updateFilters) {
                            this.filters = response.data.filters;
                            console.log(this.filters);
						}
                    }, () => {
                        console.log('Get filters error!');
                    });
                },
                postFilters: function (filters, refreshFilter) {
                    // clears the timer on a call so there is always x seconds in between calls
                    clearTimeout(this.timer);

                    console.log(filters);

                    // if the timer resets before it hits 150ms it will not run
                    this.timer = setTimeout(() => {
                        this.$http.post(maintenancePostFiltersUrl, {filters: filters, search: this.search}).then(response => {
                            this.getFilters(refreshFilter);

                        }, () => {
                            console.log('Post filters error!');
                        });
                    }, 1600);
                },
                resetFilters: function () {
                    this.search = "";
                    dataTable.columns(1).search('');
                    this.postFilters([], true);
                },
                changeFilters: function (option, id) {
                    if(!option.checked){
						this.filters[id].isCheckAll = false
					}
                    console.log(this.filters);
                    this.postFilters(this.filters, true);
                },
                selectAllMaintenance: function () {
                    var ids = this.maintenance = [];
                    $tbody.find('tr').each(function () {
                        ids.push($(this).addClass('info selected').find('.click-open-notes').eq(0).data('id'));
                    });
                },
                clearSelectAllMaintenance: function () {
                    this.maintenance = [];
                    $tbody.find('tr').removeClass('info selected');
                },
                changeMaintenanceStatus: function (status) {
                    this.$http.post(postStatusUpdate, {
                        status: status,
                        items: this.maintenance
                    }).then(function (response) {
                        console.log(response, response.body);
                        if (response.body.success) {
                            $.each(this.maintenance, function (index, value) {
                                $tbody.find("[data-status-id='" + value + "']").attr('class', 'status ' + response.body.status);
                            });
                        } else {
                            console.log('Status update success false!');
                        }
                    }, function () {
                        console.log('Status update error!');
                    });
                },
                collapse: function (index, filter) {
                    filter.collapsed = !filter.collapsed;
                    this.$http.post(maintenanceCollapseFilterUrl, {filter: {index: index, collapsed: filter.collapsed}}).then(function(response){
                        console.log(index + 'Collapse state ' + filter.collapsed );
                    }, function(){
                        console.log('Post filters error!');
                    });
                }
            },
            watch: {
                'show_archived': function (show_archived) {
                    this.$http.post(maintenanceShowArchivedUrl, {show_archived: show_archived}).then(() => {
                        this.getFilters(true);
                    });
                }
            }
        });

        // select table row
        $tbody.on('click', 'tr', function () {
            var $row = $(this);
            var maintenanceId = $row.find('.click-open-notes').eq(0).data('id');

            if ($row.hasClass('info selected')) {
                $row.removeClass('info selected');
                maintenance.maintenance.splice(maintenance.maintenance.indexOf(maintenanceId), 1);
            } else {
                maintenance.maintenance.push(maintenanceId);
                $row.addClass('info selected');
            }
        });

        // remove selects on table rows if new start loading (on scrolling)
        dataTable.on('preXhr', maintenance.clearSelectAllMaintenance);

        $(document).on('click', 'button.updating_planning', function (e) {
            e.preventDefault();
            $.post($(this).data('action'), function (data) {
                maintenance.getFilters(true);
            });
        });

        // prevent caching of modal
        $('#planing_update_confirm').on('hidden.bs.modal', function () {
            $(this).removeData('bs.modal');
        });
        $('#remind_maintenance_confirm').on('hidden.bs.modal', function () {
            $(this).removeData('bs.modal');
        });
        $('#remind_maintenance_logs').on('hidden.bs.modal', function () {
            $(this).removeData('bs.modal');
        });
    }
});
